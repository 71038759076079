import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { Organization, UserInfo } from "../../api/Organization";
import { UserContext } from "../../contexts/user-context";
import { createErrorModal } from "../../helpers/error-helpers";
import { useIsAuthenticated } from "../../hooks/use-is-authenticated";
import { Loader } from "../loader";

interface Props {
    children: JSX.Element;
}

export const UserProvider = ({ children }: Props) => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>();
    const [userPhotoVersion, setUserPhotoVersion] = useState(uuid());
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated) {
            setUserInfo(null);
            return;
        }

        const abortController = new AbortController();
        const getData = async () => {
            try {
                const userInfo = await Organization.UserInfo({
                    signal: abortController.signal,
                });
                setUserInfo(userInfo);
            } catch (error) {
                console.error(error);
                createErrorModal({
                    message:
                        "Nepavyko gauti vartotojo informacijos. Prašome bandyti atidaryti puslapį vėliau.",
                });
            }
        };
        getData();
        return () => {
            abortController.abort();
        };
    }, [isAuthenticated]);

    if (userInfo === undefined) {
        return <Loader />;
    }

    return (
        <UserContext.Provider
            value={{
                userInfo,
                userPhotoVersion,
                refreshPhoto: () => setUserPhotoVersion(uuid()),
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
