import {
    forwardRef,
    RefAttributes,
    useRef,
    useState,
    useImperativeHandle,
} from "react";
import SelectComponent, {
    ActionMeta,
    GroupBase,
    OnChangeValue,
    SingleValue,
} from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

export type Group = GroupBase<Option>;

type SelectProps = StateManagerProps<Option, false, Group> &
    RefAttributes<Select<Option, false, Group>>;

interface Props extends SelectProps {
    required?: boolean;
}

export interface Option {
    value: string;
    label: string;
}

export type Ref = { elementRef: Select<Option, false, Group> | null };

// Inspired by: https://codesandbox.io/s/react-select-v2-required-input-3xvvb?file=/src/FixRequiredSelect.js:353-422

export const ReactSelect = forwardRef<Ref, Props>((props, ref) => {
    const selectRef = useRef<Select<Option, false, Group> | null>(null);
    const [value, setValue] = useState<SingleValue<Option>>();
    const { required, ...restProps } = props;
    const { isDisabled } = props;
    const enableRequired = !isDisabled;

    useImperativeHandle(ref, () => ({
        elementRef: selectRef.current,
    }));

    const onChange = (
        value: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>,
    ) => {
        props.onChange?.(value, actionMeta);
        setValue(value);
    };
    const getValue = () => {
        const propsValue = props.defaultValue as SingleValue<Option> | null;
        return propsValue != null ? propsValue.value : value?.value || "";
    };

    return (
        <div>
            <SelectComponent
                {...restProps}
                ref={selectRef}
                onChange={onChange}
                noOptionsMessage={() => "Nėra pasirinkimo"}
                styles={{
                    menu: (provided) => {
                        return { ...provided, zIndex: 15 };
                    },
                }}
            />
            {enableRequired && (
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{
                        opacity: 0,
                        height: 0,
                        position: "absolute",
                    }}
                    onChange={() => {
                        // Do nothing...
                    }}
                    value={getValue()}
                    onFocus={() => selectRef.current?.focus()}
                    required={required}
                />
            )}
        </div>
    );
});
