import { Link } from "react-router-dom";
import { MainLayout } from "../components/main-layout/MainLayout";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";

export const HomePage = () => {
    return (
        <MainLayout>
            <div className="px-4 py-5 my-5 text-center">
                <h1 className="display-5 fw-bold">Sveiki atvykę!</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">
                        Valdykite klientų rezervacijas bei darbuotojų užimtumą.
                    </p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <Link
                            className="btn btn-primary btn-lg px-4 gap-3"
                            to="/schedule-week"
                        >
                            <AuthenticatedTemplate>
                                Peržvelgti grafiką
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                Prisijungti
                            </UnauthenticatedTemplate>
                        </Link>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};
