import { useContext } from "react";
import { UserType } from "../../api/Organization";
import { UserContext } from "../../contexts/user-context";
import { MainLayout } from "../main-layout/MainLayout";
import s from "./show-by-user-type.module.scss";

interface Props {
    showBellowUserType?: UserType;
    fallback?: (() => JSX.Element) | null;
    children: JSX.Element;
}

export const ShowByUserType = ({
    children,
    showBellowUserType = UserType.Worker,
    fallback = () => {
        return (
            <MainLayout>
                <div className={s.errorMessage}>
                    Šis puslapis Jums neprieinamas.
                </div>
            </MainLayout>
        );
    },
}: Props) => {
    const { userInfo } = useContext(UserContext);

    if (userInfo && userInfo.userType < showBellowUserType) {
        return children;
    }

    return fallback === null ? null : fallback();
};
