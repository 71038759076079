import { useState } from "react";
import classNames from "classnames";
import { WorkerApi } from "../../api/Worker";
import { createErrorModal } from "../../helpers/error-helpers";
import { AsyncImage } from "../async-image/async-image";
import { ModalLayout } from "../modal/ModalLayout";
import s from "./employee-image-modal.module.scss";

interface Props {
    onClose: () => void;
    onRemove?: (
        setDisabled: () => void,
        setError: (message: string) => void,
    ) => void;
    onSave: (
        formData: FormData,
        setError: (error: string) => void,
        setDisabled: () => void,
    ) => void;
    workerId: string;
}

const FILE_INPUT_ID = "file-input";

export const EmployeeImageModal = ({ workerId, onSave, onClose }: Props) => {
    const [disabled, setDisabled] = useState(false);
    const [photoUrl, setPhotoUrl] = useState(WorkerApi.getPhotoUrl(workerId));
    const formId = "employee-image-form";
    return (
        <ModalLayout
            title="Įkelti nuotrauką"
            onClose={onClose}
            formId={formId}
            disabled={disabled}
        >
            <form
                id={formId}
                onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    onSave(
                        formData,
                        (error) => {
                            createErrorModal({ message: error });
                            setDisabled(false);
                        },
                        () => setDisabled(true),
                    );
                }}
            >
                <div className="mb-3">
                    <label htmlFor={FILE_INPUT_ID} className="form-label">
                        Nuotrauka
                    </label>
                    <div>
                        <AsyncImage
                            src={photoUrl}
                            className={classNames("me-2", s.photo)}
                            fallback={(_status, { className }) => {
                                return (
                                    <svg
                                        version="1.2"
                                        baseProfile="tiny-ps"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 25 30"
                                        width="128"
                                        height="128"
                                        className={classNames(
                                            className,
                                            s.placeholder,
                                        )}
                                    >
                                        <path d="M24.98 24.81C24.98 26.23 24.57 27.45 23.76 28.46C22.94 29.48 21.96 29.98 20.82 29.99L4.16 29.99C3.01 29.99 2.03 29.48 1.22 28.46C0.41 27.45 0 26.23 0 24.81C0 23.71 0.05 22.67 0.16 21.68C0.27 20.7 0.48 19.71 0.78 18.72C1.08 17.72 1.46 16.87 1.92 16.16C2.38 15.45 3 14.87 3.76 14.42C4.52 13.98 5.39 13.75 6.38 13.75C8.09 15.42 10.12 16.25 12.49 16.25C14.85 16.25 16.89 15.42 18.59 13.75C19.58 13.75 20.46 13.98 21.22 14.42C21.98 14.87 22.59 15.45 23.05 16.16C23.51 16.87 23.89 17.72 24.2 18.72C24.5 19.71 24.7 20.7 24.81 21.68C24.91 22.67 24.97 23.71 24.98 24.81ZM19.98 7.51C19.98 9.57 19.25 11.34 17.78 12.8C16.32 14.27 14.55 15 12.49 15C10.42 15 8.65 14.27 7.19 12.8C5.72 11.34 4.99 9.57 5 7.51C5 5.44 5.73 3.67 7.19 2.21C8.65 0.75 10.41 0.01 12.49 0.01C14.56 0.01 16.32 0.75 17.78 2.21C19.24 3.67 19.97 5.44 19.98 7.51L19.98 7.51Z"></path>
                                    </svg>
                                );
                            }}
                        />
                    </div>

                    <input
                        autoFocus
                        type="file"
                        name="formFile"
                        className="form-control"
                        id={FILE_INPUT_ID}
                        required
                        minLength={2}
                        onChange={(event) => {
                            const [file] = event.target.files || [];
                            setPhotoUrl(
                                file
                                    ? URL.createObjectURL(file)
                                    : WorkerApi.getPhotoUrl(workerId),
                            );
                        }}
                    />
                </div>
            </form>
        </ModalLayout>
    );
};
