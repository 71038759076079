import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Loader } from "../components/loader";

export const LogoutPage = () => {
    const { instance } = useMsal();
    useEffect(() => {
        instance.logout({ postLogoutRedirectUri: "/" });
    }, []);
    return <Loader />;
};
