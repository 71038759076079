export const MONTH_TITLE_MAP: Record<number, string> = {
    0: "sausio",
    1: "vasario",
    2: "kovo",
    3: "balandžio",
    4: "gegužės",
    5: "birželio",
    6: "liepos",
    7: "rugpjūčio",
    8: "rugsėjo",
    9: "spalio",
    10: "lapkričio",
    11: "gruodžio",
};

export const WEEKDAY_TITLE: Record<number, string> = {
    0: "sekmadienis",
    1: "pirmadienis",
    2: "antradienis",
    3: "trečiadienis",
    4: "ketvirtadienis",
    5: "penktadienis",
    6: "šeštadienis",
    7: "sekmadienis",
};
