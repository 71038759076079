import { Reducer, useEffect, useReducer } from "react";
import { createRoot } from "react-dom/client";
import { Status } from "../api/ApiContracts";
import { Category, Organization } from "../api/Organization";
import { ServiceCategoryModal } from "../components/service-category-modal/ServiceCategoryModal";
import { ErrorSection } from "../components/error-section/ErrorSection";
import { Loader } from "../components/loader";
import { MainLayout } from "../components/main-layout/MainLayout";
import { useIsMounted } from "../hooks/useIsMounted";
import { CategoryApi } from "../api/Category";
import { createErrorModal } from "../helpers/error-helpers";
import s from "./ServiceCategoriesPage.module.scss";

interface State {
    categories: Category[];
    categoriesStatus: Status;
    categoriesFetchId: number;
}

enum ActionType {
    AddCategory = "add-category",
    UpateCategory = "update-category",
    RemoveCategory = "remove-category",
    SetCategoriesStatus = "set-status",
    RetryCategoriesLoad = "retry-categories-load",
}

interface AddCategoryAction {
    type: ActionType.AddCategory;
    category: Category;
}

interface UpdateCategoryAction {
    type: ActionType.UpateCategory;
    category: Category;
}

interface SetStatusAction {
    type: ActionType.SetCategoriesStatus;
    status: Status;
    categories?: Category[];
}

interface RetryCategoriesLoadAction {
    type: ActionType.RetryCategoriesLoad;
}

interface RemoveCategory {
    type: ActionType.RemoveCategory;
    categoryId: string;
}

type Action =
    | AddCategoryAction
    | UpdateCategoryAction
    | SetStatusAction
    | RetryCategoriesLoadAction
    | RemoveCategory;

export const ServiceCategoriesPage = () => {
    const isMounted = useIsMounted();
    const [state, dispatch] = useReducer<Reducer<State, Action>>(
        (state, action) => {
            switch (action.type) {
                case ActionType.AddCategory: {
                    return {
                        ...state,
                        categories: [...state.categories, action.category],
                    };
                }
                case ActionType.UpateCategory: {
                    return {
                        ...state,
                        categories: state.categories.map((category) => {
                            return category.id === action.category.id
                                ? action.category
                                : category;
                        }),
                    };
                }
                case ActionType.SetCategoriesStatus: {
                    return {
                        ...state,
                        categories: action.categories || [],
                        categoriesStatus: action.status,
                    };
                }
                case ActionType.RetryCategoriesLoad: {
                    return {
                        ...state,
                        categories: [],
                        categoriesStatus: Status.Pending,
                        categoriesFetchId: state.categoriesFetchId + 1,
                    };
                }
                case ActionType.RemoveCategory: {
                    return {
                        ...state,
                        categories: state.categories.filter(
                            (category) => category.id !== action.categoryId,
                        ),
                    };
                }
            }
        },
        {
            categories: [],
            categoriesStatus: Status.Pending,
            categoriesFetchId: 0,
        },
    );

    useEffect(() => {
        const abortController = new AbortController();
        const loadData = async () => {
            try {
                const organizationInfo =
                    await Organization.InsideOrganizationInfo({
                        signal: abortController.signal,
                    });
                if (!isMounted()) {
                    return;
                }

                dispatch({
                    type: ActionType.SetCategoriesStatus,
                    status: Status.Loaded,
                    categories: organizationInfo.categoryList,
                });
            } catch (error) {
                if (
                    error instanceof DOMException &&
                    (error.name === "AbortError" ||
                        error.code === 20 ||
                        !isMounted())
                ) {
                    return;
                }

                console.error(error);
                dispatch({
                    type: ActionType.SetCategoriesStatus,
                    status: Status.Error,
                });
            }
        };
        loadData();

        return () => abortController.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.categoriesFetchId]);

    if (state.categoriesStatus === Status.Pending) {
        return (
            <MainLayout>
                <Loader />
            </MainLayout>
        );
    }

    if (state.categoriesStatus === Status.Error) {
        return (
            <MainLayout>
                <ErrorSection
                    onRetryClick={() =>
                        dispatch({
                            type: ActionType.RetryCategoriesLoad,
                        })
                    }
                />
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <div className="container">
                <div className="d-flex flex-row align-items-center">
                    <h1 className="py-4">Paslaugų kategorijos</h1>
                    <button
                        type="button"
                        className="btn btn-primary ms-4"
                        onClick={() => {
                            const root = createRoot(
                                document.getElementById("modal")!,
                            );
                            root.render(
                                <ServiceCategoryModal
                                    onSave={async (
                                        newCategoryName,
                                        setDisabed,
                                    ) => {
                                        setDisabed(true);
                                        try {
                                            const category =
                                                await CategoryApi.create(
                                                    newCategoryName,
                                                );
                                            setDisabed(false);
                                            root.unmount();
                                            dispatch({
                                                type: ActionType.AddCategory,
                                                category,
                                            });
                                        } catch (error) {
                                            setDisabed(false);
                                            console.error(error);
                                            createErrorModal({
                                                message:
                                                    "Nepavyko sukurti įrašo. Prašome bandyti vėliau.",
                                            });
                                        }
                                    }}
                                    onClose={() => {
                                        root.unmount();
                                    }}
                                />,
                            );
                        }}
                    >
                        Sukurti įrašą
                    </button>
                </div>
                <div className={s.tableContainer}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Pavadinimas</th>
                                <th scope="col">Veiksmai</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.categories.map((category) => {
                                return (
                                    <tr key={category.id}>
                                        <td>{category.name}</td>
                                        <td>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => {
                                                    const root = createRoot(
                                                        document.getElementById(
                                                            "modal",
                                                        )!,
                                                    );

                                                    root.render(
                                                        <ServiceCategoryModal
                                                            initialData={
                                                                category
                                                            }
                                                            onSave={async (
                                                                newCategoryName,
                                                                setDisabed,
                                                            ) => {
                                                                setDisabed(
                                                                    true,
                                                                );
                                                                try {
                                                                    const updatedCategory =
                                                                        await CategoryApi.update(
                                                                            category.id,
                                                                            newCategoryName,
                                                                        );
                                                                    setDisabed(
                                                                        false,
                                                                    );
                                                                    root.unmount();
                                                                    dispatch({
                                                                        type: ActionType.UpateCategory,
                                                                        category:
                                                                            updatedCategory,
                                                                    });
                                                                } catch (error) {
                                                                    setDisabed(
                                                                        false,
                                                                    );
                                                                    console.error(
                                                                        error,
                                                                    );
                                                                    createErrorModal(
                                                                        {
                                                                            message:
                                                                                "Nepavyko atnaujinti įrašo. Prašome bandyti vėliau.",
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                            onClose={() => {
                                                                root.unmount();
                                                            }}
                                                            onRemove={async () => {
                                                                try {
                                                                    await CategoryApi.delete(
                                                                        category.id,
                                                                    );
                                                                    root.unmount();
                                                                    dispatch({
                                                                        categoryId:
                                                                            category.id,
                                                                        type: ActionType.RemoveCategory,
                                                                    });
                                                                } catch (error) {
                                                                    console.error(
                                                                        error,
                                                                    );
                                                                    createErrorModal(
                                                                        {
                                                                            message:
                                                                                "Nepavyko ištrinti įrašo. Prašome bandyti vėliau.",
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                        />,
                                                    );
                                                }}
                                            >
                                                Keisti
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </MainLayout>
    );
};
