import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const API_BASE_URL = "https://app-samis-1.azurewebsites.net";
export const ORGANIZATION_ID = "6507f3d5-ba4e-44f9-b0d0-33e7ab5810d1";
// MSAL configuration
const AUTH_CONFIG: Configuration = {
    auth: {
        clientId: "a158a6d5-13e4-4b8b-be2f-51ab7e78247a",
        authority:
            "https://login.microsoftonline.com/9b5f0df8-675b-458a-9c72-1e97137484d4",
        redirectUri: "/callback",
        navigateToLoginRequestUrl: true,
    },
};
export const MSAL_INSTANCE = new PublicClientApplication(AUTH_CONFIG);
