import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";

interface Options {
  signal?: AbortSignal;
}

interface CreateServiceDto {
  id: string;
  organizationId: string;
  name: string;
  categoryId: string;
  length: string;
  workerIdList: {
    workerId: string;
    price: number;
  }[];
}

interface UpdateServiceDto {
  id: string;
  organizationId: string;
  name: string;
  categoryId: string;
  length: string;
  workerIdList: {
    workerId: string;
    price: number;
  }[];
}

interface OrderItem {
  CategoryId: string;
  ServiceIds: string[];
}

interface OrderRequest {
  Order: OrderItem[];
}

const BASE_URL = `${API_BASE_URL}/api/Service`;

export const ServiceApi = {
  async create(createServiceDto: CreateServiceDto, requestOptions: Options) {
    const result = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-OrganizationId": ORGANIZATION_ID,
        Authorization: await getAuthorizationHeader(),
      },
      body: JSON.stringify(createServiceDto),
      ...requestOptions,
    });

    if (!result.ok) {
      throw result.json();
    }
  },
  async delete(id: string, requestOptions: Options) {
    const result = await fetch(`${BASE_URL}/${id}`, {
      method: "DELETE",
      headers: {
        "X-OrganizationId": ORGANIZATION_ID,
        Authorization: await getAuthorizationHeader(),
      },
      ...requestOptions,
    });

    if (!result.ok) {
      throw await result.json();
    }
  },
  async update(
    id: string,
    updateServiceDto: UpdateServiceDto,
    requestOptions: Options,
  ) {
    const result = await fetch(`${BASE_URL}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-OrganizationId": ORGANIZATION_ID,
        Authorization: await getAuthorizationHeader(),
      },
      body: JSON.stringify(updateServiceDto),
      ...requestOptions,
    });

    if (!result.ok) {
      throw await result.json();
    }
  },
  async order(payload: OrderRequest) {
    const result = await fetch(`${BASE_URL}/Order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-OrganizationId": ORGANIZATION_ID,
        Authorization: await getAuthorizationHeader(),
      },
      body: JSON.stringify(payload),
    });

    if (!result.ok) {
      throw await result.json();
    }
  },
};
