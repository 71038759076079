import { useState } from "react";
import { createRoot } from "react-dom/client";
import { Category } from "../../api/Organization";
import { ModalLayout } from "../modal/ModalLayout";

interface Props {
    onClose: () => void;
    onRemove?: () => void;
    onSave: (
        categoryName: string,
        setDisabled: (disabled: boolean) => void,
    ) => void;
    initialData?: Partial<Category>;
}

export const ServiceCategoryModal = ({
    initialData = {},
    onSave,
    onClose,
    onRemove,
}: Props) => {
    const [disabled, setDisabled] = useState(false);
    const formId = "category-form";
    return (
        <ModalLayout
            title={
                initialData.name
                    ? "Redaguoti kategorijos įrašą"
                    : "Sukurti kategorijos įrašą"
            }
            onClose={onClose}
            formId={formId}
            onRemoveClick={
                onRemove
                    ? () => {
                          const root = createRoot(
                              document.getElementById("modal-confirm")!,
                          );
                          root.render(
                              <ModalLayout
                                  autoFocusCancel
                                  title="Ištrinti kategoriją"
                                  saveTitle="Ištrinti"
                                  saveClassName="btn-danger"
                                  cancelTitle="Atšaukti"
                                  onClose={() => root.unmount()}
                                  onSave={() => {
                                      root.unmount();
                                      onRemove?.();
                                  }}
                              >
                                  Ar tikrai norite ištrinti kategoriją?
                              </ModalLayout>,
                          );
                      }
                    : undefined
            }
        >
            <form
                id={formId}
                onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    onSave(formData.get("name") as string, (newValue) =>
                        setDisabled(newValue),
                    );
                }}
            >
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        Pavadinimas
                    </label>
                    <input
                        autoFocus
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                        disabled={disabled}
                        minLength={2}
                        maxLength={100}
                        defaultValue={initialData.name}
                    />
                </div>
            </form>
        </ModalLayout>
    );
};
