import { Calendar, dateFnsLocalizer, HeaderProps } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import getDay from "date-fns/getDay";
import { lt } from "date-fns/locale";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { v4 as uuid } from "uuid";
import classNames from "classnames";

import { MainLayout } from "../components/main-layout/MainLayout";
import {
    Employee,
    Organization,
    OrganizationInfo,
    UserType,
} from "../api/Organization";
import { Reducer, useEffect, useReducer, useRef } from "react";
import { ResponseDto, Status } from "../api/ApiContracts";
import { useIsMounted } from "../hooks/useIsMounted";
import { Loader } from "../components/loader";
import { ErrorSection } from "../components/error-section/ErrorSection";
import { WorkingTimeSlot } from "../api/WorkingTimeSlot";
import { createRoot } from "react-dom/client";
import { WorkingTimeSlotModal } from "../components/working-time-slot-modal/WorkingTimeSlotModal";
import { ReactSelect } from "../components/react-select/ReactSelect";
import { ModalLayout } from "../components/modal/ModalLayout";
import { WorkerTime, WorkerTimeApi } from "../api/WorkerTime";
import { createErrorModal } from "../helpers/error-helpers";
import s from "./WorkingTimePage.module.scss";

export const TIME_SLOT_NAME = "Darbo laikas";

const DnDCalendar = withDragAndDrop(Calendar);
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: { lt },
});

interface Option {
    value: string;
    label: string;
}

interface State {
    slots: WorkingTimeSlot[];
    employeesOptions: Option[];
    employee?: Employee;
    organizationInfoFetchId: number;
    organizationInfoResponse: ResponseDto<OrganizationInfo>;
    workerTimeResponse: ResponseDto<WorkerTime[]>;
    workerTimeFetchId: number;
    start: Date;
    end: Date;
    areTimesLoading: boolean;
}

enum ActionType {
    AddSlot = "add-slot",
    UpdateSlot = "update-slot",
    RemoveSlot = "remove-slot",
    SetOrganizationStatus = "set-organization-status",
    ReloadOrganizationInfo = "reload-organization-info",
    SetEmployee = "set-employee",
    CopyLastFilledDay = "copy-last-fille-day",
    SetWorkerTimeStatus = "set-worker-time-status",
    ReloadWorkerTime = "reload-worker-time",
    SetLoading = "set-loading",
}

interface AddEventAction {
    type: ActionType.AddSlot;
    slot: WorkingTimeSlot;
}

interface UpdateEventAction {
    type: ActionType.UpdateSlot;
    slot: WorkingTimeSlot;
}

interface RemoveEventAction {
    type: ActionType.RemoveSlot;
    id: string;
}

interface SetOrganizationInfoStatusAction {
    type: ActionType.SetOrganizationStatus;
    responseDto: ResponseDto<OrganizationInfo>;
}

interface ReloadOrganizationInfoAction {
    type: ActionType.ReloadOrganizationInfo;
}

interface SetEmployeeAction {
    type: ActionType.SetEmployee;
    id: string;
}

interface CopyLastFilledDayAction {
    type: ActionType.CopyLastFilledDay;
    copiedSlots: WorkingTimeSlot[];
}

interface SetWorkerTimeStatusAction {
    type: ActionType.SetWorkerTimeStatus;
    responseDto: ResponseDto<WorkerTime[]>;
    start?: Date;
    end?: Date;
}

interface ReloadWorkerTimeAction {
    type: ActionType.ReloadWorkerTime;
}

interface SetLoadingAction {
    type: ActionType.SetLoading;
    isLoading: boolean;
}

type Action =
    | AddEventAction
    | UpdateEventAction
    | RemoveEventAction
    | SetOrganizationInfoStatusAction
    | ReloadOrganizationInfoAction
    | SetEmployeeAction
    | CopyLastFilledDayAction
    | SetWorkerTimeStatusAction
    | ReloadWorkerTimeAction
    | SetLoadingAction;

const minDate = new Date(1972, 0, 1, 6, 0, 0, 0);
const maxDate = new Date(1972, 0, 1, 22, 0, 0, 0);

const resolveRangeExtremes = (range: Date[] | { start: Date; end: Date }) => {
    // 'week' range.
    if (Array.isArray(range)) {
        return {
            start: addDays(range[0], 1),
            end: endOfDay(addDays(range[range.length - 1], 1)),
        };
    }

    // 'month'
    return {
        start: startOfDay(addDays(range.start, 1)),
        end: addDays(range.end, 1),
    };
};

const resolveEmployeeOptions = (
    workerList?: Employee[],
    selectedEmployee?: Employee,
) => {
    return (workerList || []).reduce<Option[]>((options, employee) => {
        if (
            employee.id === selectedEmployee?.id ||
            // Only allow selecting manager worker or worker for time scheduling.
            ![UserType.ManagerWorker, UserType.Worker].includes(
                employee.userType,
            )
        ) {
            return options;
        }

        options.push({
            value: employee.id,
            label: employee.displayName,
        });

        return options;
    }, []);
};

export const WorkingTimePage = () => {
    const isMounted = useIsMounted();

    const [state, dispatch] = useReducer<Reducer<State, Action>>(
        (state, action): State => {
            switch (action.type) {
                case ActionType.AddSlot: {
                    return {
                        ...state,
                        slots: [...state.slots, action.slot],
                    };
                }
                case ActionType.UpdateSlot: {
                    return {
                        ...state,
                        slots: state.slots.reduce<WorkingTimeSlot[]>(
                            (newEvents, event) => {
                                if (event.id === action.slot.id) {
                                    newEvents.push(action.slot);
                                } else {
                                    newEvents.push(event);
                                }

                                return newEvents;
                            },
                            [],
                        ),
                    };
                }
                case ActionType.RemoveSlot: {
                    return {
                        ...state,
                        slots: [
                            ...state.slots.filter(({ id }) => action.id !== id),
                        ],
                    };
                }
                case ActionType.SetOrganizationStatus: {
                    const employee = action.responseDto.value?.workerList[0];
                    return {
                        ...state,
                        employee,
                        organizationInfoResponse: action.responseDto,
                        employeesOptions: resolveEmployeeOptions(
                            action.responseDto.value?.workerList,
                            employee,
                        ),
                    };
                }
                case ActionType.ReloadOrganizationInfo: {
                    return {
                        ...state,
                        organizationInfoResponse: {
                            value: undefined,
                            status: Status.Pending,
                        },
                        organizationInfoFetchId:
                            state.organizationInfoFetchId + 1,
                    };
                }
                case ActionType.SetEmployee: {
                    const employee =
                        state.organizationInfoResponse.value?.workerList.find(
                            (employee) => employee.id === action.id,
                        );
                    return {
                        ...state,
                        employee,
                        employeesOptions: resolveEmployeeOptions(
                            state.organizationInfoResponse.value?.workerList,
                            employee,
                        ),
                    };
                }
                case ActionType.CopyLastFilledDay: {
                    return {
                        ...state,
                        slots: [...state.slots, ...action.copiedSlots],
                    };
                }
                case ActionType.SetWorkerTimeStatus: {
                    const { responseDto, start, end } = action;

                    return {
                        ...state,
                        start: start ?? state.start,
                        end: end ?? state.end,
                        slots:
                            responseDto.value?.map(
                                ({ id, workerId, date, start, end }) => {
                                    return {
                                        id,
                                        workerId,
                                        start: new Date(`${date}T${start}`),
                                        end: new Date(`${date}T${end}`),
                                    };
                                },
                            ) || [],
                        workerTimeResponse: responseDto,
                    };
                }
                case ActionType.ReloadWorkerTime: {
                    return {
                        ...state,
                        workerTimeResponse: {
                            value: undefined,
                            status: Status.Pending,
                        },
                        organizationInfoFetchId:
                            state.organizationInfoFetchId + 1,
                    };
                }
                case ActionType.SetLoading: {
                    return {
                        ...state,
                        areTimesLoading: action.isLoading,
                    };
                }
            }
        },
        {
            slots: [],
            employeesOptions: [],
            organizationInfoResponse: {
                value: undefined,
                status: Status.Pending,
            },
            workerTimeResponse: {
                value: undefined,
                status: Status.Pending,
            },
            organizationInfoFetchId: 0,
            workerTimeFetchId: 0,
            start: startOfWeek(new Date(), { weekStartsOn: 1 }),
            end: endOfWeek(new Date(), { weekStartsOn: 1 }),
            areTimesLoading: false,
        },
    );

    useEffect(() => {
        const abortController = new AbortController();
        const loadData = async () => {
            try {
                const organizationInfo =
                    await Organization.InsideOrganizationInfo({
                        signal: abortController.signal,
                    });
                if (!isMounted()) {
                    return;
                }

                dispatch({
                    type: ActionType.SetOrganizationStatus,
                    responseDto: {
                        value: organizationInfo,
                        status: Status.Loaded,
                    },
                });
            } catch (error) {
                if (
                    error instanceof DOMException &&
                    (error.name === "AbortError" ||
                        error.code === 20 ||
                        !isMounted())
                ) {
                    return;
                }

                console.error(error);
                dispatch({
                    type: ActionType.SetOrganizationStatus,
                    responseDto: {
                        value: undefined,
                        status: Status.Error,
                    },
                });
            }
        };
        loadData();

        return () => abortController.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.organizationInfoFetchId]);

    useEffect(() => {
        if (!state.employee?.id) {
            return;
        }

        const abortController = new AbortController();
        const loadData = async () => {
            if (!state.employee?.id) {
                return;
            }

            try {
                dispatch({
                    type: ActionType.SetWorkerTimeStatus,
                    responseDto: {
                        value: undefined,
                        status: Status.Pending,
                    },
                });
                const workerTime = await WorkerTimeApi.search(
                    state.employee.id,
                    format(state.start, "y-MM-dd"),
                    format(state.end, "y-MM-dd"),
                    {
                        signal: abortController.signal,
                    },
                );
                if (!isMounted()) {
                    return;
                }
                dispatch({
                    type: ActionType.SetWorkerTimeStatus,
                    responseDto: {
                        value: workerTime,
                        status: Status.Loaded,
                    },
                });
            } catch (error) {
                if (
                    error instanceof DOMException &&
                    (error.name === "AbortError" ||
                        error.code === 20 ||
                        !isMounted())
                ) {
                    return;
                }

                console.error(error);
                dispatch({
                    type: ActionType.SetWorkerTimeStatus,
                    responseDto: {
                        value: undefined,
                        status: Status.Error,
                    },
                });
            }
        };

        loadData();
    }, [state.employee?.id, state.start, state.end, state.workerTimeFetchId]);

    // Proxyying selected employee id to cached components.
    const selectedEmployeeRef = useRef(state.employee?.id);
    useEffect(() => {
        selectedEmployeeRef.current = state.employee?.id;
    }, [state.employee?.id]);

    if (state.organizationInfoResponse.status === Status.Pending) {
        return (
            <MainLayout className={s.content}>
                <Loader />
            </MainLayout>
        );
    }

    if (state.organizationInfoResponse.status === Status.Error) {
        return (
            <MainLayout className={s.content}>
                <ErrorSection
                    onRetryClick={() =>
                        dispatch({ type: ActionType.ReloadOrganizationInfo })
                    }
                />
            </MainLayout>
        );
    }

    const isMultiworkerList =
        state.organizationInfoResponse.value.workerList.length > 1;

    const loadWorkingTimesByExtremes = async (
        start: Date,
        end: Date,
        employeeId?: string,
    ) => {
        if (!employeeId) {
            return;
        }

        dispatch({
            type: ActionType.SetWorkerTimeStatus,
            responseDto: {
                value: undefined,
                status: Status.Pending,
            },
            start,
            end,
        });

        try {
            const workingTimes = await WorkerTimeApi.search(
                employeeId,
                format(start, "y-MM-dd"),
                format(end, "y-MM-dd"),
                {},
            );

            if (!isMounted()) {
                return;
            }
            dispatch({
                type: ActionType.SetWorkerTimeStatus,
                responseDto: {
                    value: workingTimes,
                    status: Status.Loaded,
                },
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: ActionType.SetWorkerTimeStatus,
                responseDto: {
                    value: undefined,
                    status: Status.Error,
                },
            });
        }
    };

    return (
        <MainLayout className={s.content}>
            {isMultiworkerList ? (
                <div className="p-3">
                    <label htmlFor="employee-filter" className="col-sm-2 ">
                        Pasirinkite darbuotoją:
                    </label>
                    <ReactSelect
                        className="form-control-plaintext"
                        id="employee-filter"
                        options={state.employeesOptions}
                        value={
                            state.employee
                                ? {
                                      value: state.employee.id,
                                      label: state.employee.displayName,
                                  }
                                : undefined
                        }
                        onChange={(employeeOption) => {
                            if (employeeOption?.value) {
                                dispatch({
                                    type: ActionType.SetEmployee,
                                    id: employeeOption.value,
                                });
                                loadWorkingTimesByExtremes(
                                    state.start,
                                    state.end,
                                    employeeOption.value,
                                );
                            }
                        }}
                    />
                </div>
            ) : null}
            {state.workerTimeResponse.status === Status.Error ? (
                <ErrorSection
                    onRetryClick={() =>
                        dispatch({ type: ActionType.ReloadWorkerTime })
                    }
                />
            ) : (
                <div className={s.calendarContainer}>
                    <DnDCalendar
                        selectable
                        culture="lt"
                        step={15}
                        localizer={localizer}
                        events={state.slots}
                        // No date produce wrong range...
                        date={state.start}
                        onNavigate={() => {
                            // Using onRangeChange instead...
                        }}
                        resources={state.employee ? [state.employee] : []}
                        onSelectEvent={(rawSlot) => {
                            const slot = rawSlot as WorkingTimeSlot;
                            const employee =
                                state.organizationInfoResponse.value?.workerList.find(
                                    (x) => x.id === slot.workerId,
                                );
                            if (!employee) {
                                return;
                            }

                            const minDateToday = new Date(slot.start);
                            minDateToday.setHours(minDate.getHours());
                            minDateToday.setMinutes(minDate.getMinutes());

                            const maxDateToday = new Date(slot.start);
                            maxDateToday.setHours(maxDate.getHours());
                            maxDateToday.setMinutes(maxDate.getMinutes());

                            const root = createRoot(
                                document.getElementById("modal")!,
                            );
                            root.render(
                                <WorkingTimeSlotModal
                                    employee={employee}
                                    initialData={slot}
                                    min={minDateToday}
                                    max={maxDateToday}
                                    onClose={() => {
                                        root.unmount();
                                    }}
                                    onRemove={async (setDisabled) => {
                                        setDisabled(true);
                                        try {
                                            await WorkerTimeApi.deleteWorkerTime(
                                                slot.id,
                                            );
                                            dispatch({
                                                type: ActionType.RemoveSlot,
                                                id: slot.id,
                                            });
                                            root.unmount();
                                        } catch (error) {
                                            console.error(error);
                                            createErrorModal({
                                                message:
                                                    "Nepavyko ištrinti įrašo. Prašome bandyti vėliau.",
                                            });
                                        }
                                        setDisabled(false);
                                    }}
                                    onSave={async (
                                        updatedSlot,
                                        setDisabled,
                                    ) => {
                                        setDisabled(true);
                                        try {
                                            root.unmount();
                                            await WorkerTimeApi.update(
                                                slot.id,
                                                {
                                                    date: format(
                                                        updatedSlot.start,
                                                        "y-MM-dd",
                                                    ),
                                                    start: format(
                                                        updatedSlot.start,
                                                        "HH:mm",
                                                    ),
                                                    end: format(
                                                        updatedSlot.end,
                                                        "HH:mm",
                                                    ),
                                                },
                                            );
                                            dispatch({
                                                type: ActionType.UpdateSlot,
                                                slot: updatedSlot,
                                            });
                                        } catch (error) {
                                            console.error(error);
                                            createErrorModal({
                                                message:
                                                    "Nepavyko atnaujinti įrašo. Prašome bandyti vėliau.",
                                            });
                                        }
                                        setDisabled(false);
                                    }}
                                />,
                            );
                        }}
                        min={minDate}
                        max={maxDate}
                        onSelectSlot={({ start, end, resourceId }) => {
                            const employee =
                                state.organizationInfoResponse.value?.workerList.find(
                                    (x) => x.id === resourceId,
                                );
                            if (!employee) {
                                return;
                            }

                            const minDateToday = new Date(start);
                            minDateToday.setHours(minDate.getHours());
                            minDateToday.setMinutes(minDate.getMinutes());

                            const maxDateToday = new Date(start);
                            maxDateToday.setHours(maxDate.getHours());
                            maxDateToday.setMinutes(maxDate.getMinutes());

                            const root = createRoot(
                                document.getElementById("modal")!,
                            );
                            root.render(
                                <WorkingTimeSlotModal
                                    min={minDateToday}
                                    max={maxDateToday}
                                    employee={employee}
                                    initialData={{
                                        start,
                                        end,
                                        workerId: resourceId as string,
                                    }}
                                    onClose={() => {
                                        root.unmount();
                                    }}
                                    onSave={async (slot, setDisabled) => {
                                        setDisabled(true);
                                        try {
                                            const newSlot = {
                                                ...slot,
                                                id: uuid(),
                                            };
                                            await WorkerTimeApi.create({
                                                id: newSlot.id,
                                                workerId: employee.id,
                                                date: format(
                                                    newSlot.start,
                                                    "y-MM-dd",
                                                ),
                                                start: format(
                                                    newSlot.start,
                                                    "HH:mm",
                                                ),
                                                end: format(
                                                    newSlot.end,
                                                    "HH:mm",
                                                ),
                                            });
                                            root.unmount();
                                            dispatch({
                                                type: ActionType.AddSlot,
                                                slot: newSlot,
                                            });
                                        } catch (error) {
                                            console.error(error);
                                            setDisabled(false);
                                            createErrorModal({
                                                message:
                                                    "Nepavyko sukurti įrašo. Prašome bandyti vėliau.",
                                            });
                                        }
                                    }}
                                />,
                            );
                        }}
                        onEventResize={async ({ event, start, end }) => {
                            const slot = event as WorkingTimeSlot;
                            dispatch({
                                type: ActionType.SetLoading,
                                isLoading: true,
                            });

                            try {
                                const startDate = start as Date;
                                const endDate = end as Date;
                                dispatch({
                                    type: ActionType.UpdateSlot,
                                    slot: {
                                        ...slot,
                                        start: startDate,
                                        end: endDate,
                                    },
                                });
                                await WorkerTimeApi.update(slot.id, {
                                    date: format(startDate, "y-MM-dd"),
                                    start: format(startDate, "HH:mm"),
                                    end: format(endDate, "HH:mm"),
                                });
                            } catch (error) {
                                console.error(error);
                                dispatch({
                                    type: ActionType.UpdateSlot,
                                    slot,
                                });
                                createErrorModal({
                                    message:
                                        "Nepavyko atnaujinti įrašo. Prašome bandyti vėliau.",
                                });
                            }
                            dispatch({
                                type: ActionType.SetLoading,
                                isLoading: false,
                            });
                        }}
                        components={{
                            header: ({ label, date }: HeaderProps) => {
                                return (
                                    <div>
                                        {label}
                                        <svg
                                            version="1.2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 45 20"
                                            width="45"
                                            height="20"
                                            className={s.copyButton}
                                            onClick={() => {
                                                const root = createRoot(
                                                    document.getElementById(
                                                        "modal-confirm",
                                                    )!,
                                                );
                                                root.render(
                                                    <ModalLayout
                                                        autoFocusCancel
                                                        title="Kopijuoti paskutinę užpildytą dieną"
                                                        saveTitle="Kopijuoti"
                                                        cancelTitle="Atšaukti"
                                                        onClose={() =>
                                                            root.unmount()
                                                        }
                                                        onSave={async () => {
                                                            // As header component of the calendar is cache, can't use the state here.
                                                            // Proxying through ref.
                                                            const workerId =
                                                                selectedEmployeeRef.current;

                                                            if (!workerId) {
                                                                return;
                                                            }

                                                            const destinationDate =
                                                                date;

                                                            try {
                                                                const results =
                                                                    await WorkerTimeApi.copy(
                                                                        {
                                                                            workerId,
                                                                            toDate: format(
                                                                                destinationDate,
                                                                                "y-MM-dd",
                                                                            ),
                                                                        },
                                                                    );

                                                                root.unmount();
                                                                dispatch({
                                                                    type: ActionType.CopyLastFilledDay,
                                                                    copiedSlots:
                                                                        results.map(
                                                                            (
                                                                                slot,
                                                                            ) => ({
                                                                                ...{
                                                                                    id: slot.id,
                                                                                    workerId:
                                                                                        slot.workerId,
                                                                                    start: new Date(
                                                                                        `${slot.date}T${slot.start}`,
                                                                                    ),
                                                                                    end: new Date(
                                                                                        `${slot.date}T${slot.end}`,
                                                                                    ),
                                                                                },
                                                                            }),
                                                                        ),
                                                                });
                                                            } catch (error) {
                                                                console.error(
                                                                    error,
                                                                );
                                                                root.unmount();
                                                                createErrorModal(
                                                                    {
                                                                        message:
                                                                            "Nepavyko nukopijuoti dieną.",
                                                                    },
                                                                );
                                                                return;
                                                            }
                                                        }}
                                                    >
                                                        Ar tikrai norite
                                                        kopijuoti paskutinę
                                                        užpildytą dieną?
                                                    </ModalLayout>,
                                                );
                                            }}
                                        >
                                            <title>
                                                Kopijuoti paskutinę užpildytą
                                                dieną
                                            </title>
                                            <path d="m45 9.9q0 0.4-0.3 0.7l-10 9.2q-0.4 0.3-0.9 0.1-0.5-0.2-0.5-0.7v-5.8h-32.5q-0.3 0-0.6-0.3-0.2-0.2-0.2-0.6v-5q0-0.3 0.2-0.6 0.3-0.2 0.6-0.2h32.5v-5.8q0-0.6 0.5-0.8 0.5-0.2 0.9 0.1l10 9.1q0.3 0.3 0.3 0.6z" />
                                        </svg>
                                    </div>
                                );
                            },
                        }}
                        onEventDrop={async (args) => {
                            const { event, start, end } = args as {
                                event: WorkingTimeSlot;
                                start: Date;
                                end: Date;
                                isAllDay: boolean;
                                resourceId: string;
                            };

                            try {
                                if (start.getDate() !== event.start.getDate()) {
                                    return;
                                }

                                if (end.getDate() !== event.start.getDate()) {
                                    return;
                                }

                                dispatch({
                                    type: ActionType.SetLoading,
                                    isLoading: true,
                                });

                                const minDateToday = new Date(event.start);
                                minDateToday.setHours(minDate.getHours());
                                minDateToday.setMinutes(minDate.getMinutes());

                                const maxDateToday = new Date(event.start);
                                maxDateToday.setHours(maxDate.getHours());
                                maxDateToday.setMinutes(maxDate.getMinutes());

                                let startDate = new Date(start as Date);
                                if (startDate < minDateToday) {
                                    startDate = minDateToday;
                                }

                                let endDate = new Date(end as Date);
                                if (endDate > maxDateToday) {
                                    endDate = maxDateToday;
                                }

                                dispatch({
                                    type: ActionType.UpdateSlot,
                                    slot: {
                                        ...event,
                                        start: startDate,
                                        end: endDate,
                                    },
                                });
                                await WorkerTimeApi.update(event.id, {
                                    date: format(startDate, "y-MM-dd"),
                                    start: format(startDate, "HH:mm"),
                                    end: format(endDate, "HH:mm"),
                                });
                            } catch (error) {
                                console.error(error);
                                dispatch({
                                    type: ActionType.UpdateSlot,
                                    slot: event,
                                });
                                createErrorModal({
                                    message:
                                        "Nepavyko atnaujinti įrašo. Prašome bandyti vėliau.",
                                });
                            }
                            dispatch({
                                type: ActionType.SetLoading,
                                isLoading: false,
                            });
                        }}
                        onRangeChange={(range) => {
                            const { start, end } = resolveRangeExtremes(range);
                            loadWorkingTimesByExtremes(
                                start,
                                end,
                                state.employee?.id,
                            );
                        }}
                        className={classNames(s.calendar, {
                            [s.multiWorkersCalendar]: isMultiworkerList,
                        })}
                        views={["week"]}
                        defaultView="week"
                        timeslots={4}
                        resourceIdAccessor={(worker) => (worker as Employee).id}
                        resourceTitleAccessor={(worker) =>
                            (worker as Employee).displayName
                        }
                        resourceAccessor={(rawSlot) => {
                            const slot = rawSlot as WorkingTimeSlot;
                            return slot.workerId;
                        }}
                        messages={{
                            date: "Data",
                            time: "Laikas",
                            event: "Įvykis",
                            allDay: "Visą dieną",
                            week: "Savaitė",
                            work_week: "Darbo savaitė",
                            day: "Diena",
                            month: "Mėnuo",
                            previous: "Atgal",
                            next: "Pirmyn",
                            yesterday: "Vakar",
                            tomorrow: "Rytoj",
                            today: "Šiandien",
                            agenda: "Tvarkaraštis",
                            showMore: (count: number) => `${count} daugiau`,
                            noEventsInRange: "Nėra įvykių",
                        }}
                    />
                    {state.workerTimeResponse.status === Status.Pending ? (
                        <div className={s.calendarOverlayLoader}>
                            <Loader />
                        </div>
                    ) : null}
                </div>
            )}
            {state.areTimesLoading ? (
                <div className={s.overlayLoader}>
                    <Loader />
                </div>
            ) : null}
        </MainLayout>
    );
};
