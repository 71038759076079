import { MSAL_INSTANCE } from "../api/api-config";

export const getAuthorizationHeader = async () => {
    const account = MSAL_INSTANCE.getAllAccounts()[0];
    const accessToken = account
        ? (
              await MSAL_INSTANCE.acquireTokenSilent({
                  scopes: ["api://samis/access_as_user"],
                  account,
              })
          ).accessToken
        : "";
    return `Bearer ${accessToken}`;
};
