import classNames from "classnames";
import s from "./Loader.module.scss";

interface Props {
    className?: string;
}

export const Loader = ({ className }: Props) => {
    return (
        <div className={classNames(s.loaderContainer, className)}>
            <span className={s.loader}></span>
        </div>
    );
};
