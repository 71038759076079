import s from "./ErrorSection.module.scss";

interface Props {
    onRetryClick: () => void;
}

export const ErrorSection = ({ onRetryClick }: Props) => {
    return (
        <div className={s.errorContainer}>
            <section className={s.errorSection}>
                <h1>Ups... :(</h1>
                <div className={s.errorText}>Atsiprašome, įvyko klaida.</div>
                <span className={s.link} onClick={onRetryClick}>
                    Pabandykim dar kartą?
                </span>
            </section>
        </div>
    );
};
