import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { MSAL_INSTANCE } from "../../api/api-config";
import { AuthContext } from "../../contexts/auth-context";
import { Loader } from "../loader";

interface Props {
    children: JSX.Element;
}

MSAL_INSTANCE.enableAccountStorageEvents();

export const AuthProvider = ({ children }: Props) => {
    const [authContext, setAuthContext] = useState<AuthContext>({
        isAuthenticated: null,
    });
    const { accounts } = useMsal();

    useEffect(() => {
        const abortController = new AbortController();
        const getData = async () => {
            try {
                const account = accounts[0];
                await MSAL_INSTANCE.acquireTokenSilent({
                    scopes: ["api://samis/access_as_user"],
                    account,
                });
                if (!abortController.signal.aborted) {
                    setAuthContext({ isAuthenticated: true });
                }
            } catch (error) {
                console.error(error);
                if (!abortController.signal.aborted) {
                    setAuthContext({ isAuthenticated: false });
                }
            }
        };
        getData();
        return () => {
            abortController.abort();
        };
    }, [accounts]);

    if (authContext.isAuthenticated == null) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};
