import { Reducer, useReducer, useState } from "react";
import { createRoot } from "react-dom/client";
import { ReactSelect } from "../react-select/ReactSelect";
import { ModalLayout } from "../modal/ModalLayout";
import {
    Category,
    Employee,
    Service,
    ServiceEmployee,
} from "../../api/Organization";
import {
    getLengthFromMinutes,
    getMinutesFromLength,
} from "../../helpers/time-helpers";
import { createErrorModal } from "../../helpers/error-helpers";

import s from "./ServiceModal.module.scss";

interface Props {
    categories: Category[];
    employees: Employee[];
    onClose: () => void;
    onSave: (
        service: Service,
        setDisabled: () => void,
        setError: (message: string) => void,
    ) => void;
    initialData?: Partial<Service>;
    onRemove?: (
        setDisabled: () => void,
        setError: (message: string) => void,
    ) => void;
}

interface Option {
    label: string;
    value: string;
}

interface State {
    serviceEmployees: ServiceEmployee[];
}

enum ActionType {
    AddServiceEmployee = "add-service-employee",
    RemoveServiceEmployee = "remove-service-employee",
}

interface AddServiceEmployeeAction {
    type: ActionType.AddServiceEmployee;
    serviceEmployee: ServiceEmployee;
}

interface RemoveServiceEmployeeAction {
    type: ActionType.RemoveServiceEmployee;
    id: string;
}

type Action = AddServiceEmployeeAction | RemoveServiceEmployeeAction;

export const ServiceModal = ({
    initialData,
    categories,
    employees,
    onClose,
    onSave,
    onRemove,
}: Props) => {
    const [serviceWorker, setServiceWorker] = useState<Option | null>(null);
    const [priceString, setPriceString] = useState<string>("");
    const [state, dispatch] = useReducer<Reducer<State, Action>>(
        (state, action) => {
            switch (action.type) {
                case ActionType.AddServiceEmployee: {
                    return {
                        ...state,
                        serviceEmployees: [
                            ...state.serviceEmployees,
                            action.serviceEmployee,
                        ],
                    };
                }
                case ActionType.RemoveServiceEmployee: {
                    return {
                        ...state,
                        serviceEmployees: state.serviceEmployees.filter(
                            (x) => x.workerId !== action.id,
                        ),
                    };
                }
            }
        },
        {
            serviceEmployees: initialData?.workerIdList || [],
        },
    );
    const [disabled, setDisabled] = useState(false);
    const formId = "employee-form";
    const categoriesOptions = categories.map<Option>((category) => ({
        label: category.name,
        value: category.id,
    }));
    const employeeOptions = employees.reduce<Option[]>((options, employee) => {
        if (!state.serviceEmployees.some((x) => x.workerId === employee.id)) {
            options.push({
                label: employee.displayName,
                value: employee.id,
            });
        }
        return options;
    }, []);

    return (
        <ModalLayout
            title={
                initialData?.id
                    ? "Pakeisti paslaugos įrašą"
                    : "Sukurti paslaugos įrašą"
            }
            formId={formId}
            disabled={disabled}
            onClose={onClose}
            contentClassName={s.modelContent}
            onRemoveClick={
                onRemove
                    ? () => {
                          const root = createRoot(
                              document.getElementById("modal-confirm")!,
                          );
                          root.render(
                              <ModalLayout
                                  autoFocusCancel
                                  title="Ištrinti paslaugą"
                                  saveTitle="Ištrinti"
                                  saveClassName="btn-danger"
                                  cancelTitle="Atšaukti"
                                  onClose={() => root.unmount()}
                                  onSave={() => {
                                      root.unmount();
                                      onRemove?.(
                                          () => setDisabled(true),
                                          (message) => {
                                              setDisabled(false);
                                              createErrorModal({ message });
                                          },
                                      );
                                  }}
                              >
                                  Ar tikrai norite pašalinti paslaugą?
                              </ModalLayout>,
                          );
                      }
                    : undefined
            }
        >
            <form
                id={formId}
                onSubmit={(event) => {
                    event.preventDefault();

                    if (serviceWorker || priceString) {
                        const root = createRoot(
                            document.getElementById("modal-confirm")!,
                        );
                        root.render(
                            <ModalLayout
                                autoFocusCancel
                                title="Neužbaigėte pildyti lauko"
                                onClose={() => {
                                    root.unmount();
                                }}
                            >
                                Neužbaigėte pildyti paslaugos darbuotojo įrašo!
                            </ModalLayout>,
                        );
                        return;
                    }

                    const formData = new FormData(event.currentTarget);
                    onSave(
                        {
                            id: initialData?.id || "",
                            name: formData.get("name") as string,
                            categoryId: formData.get("category") as string,
                            workerIdList: state.serviceEmployees,
                            length: getLengthFromMinutes(
                                Number(formData.get("length")),
                            ),
                            order: initialData?.order || 0,
                        },
                        () => setDisabled(true),
                        (message) => {
                            setDisabled(false);
                            createErrorModal({ message });
                        },
                    );
                }}
            >
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        Pavadinimas
                    </label>
                    <input
                        autoFocus
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                        disabled={disabled}
                        minLength={2}
                        maxLength={300}
                        defaultValue={initialData?.name}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="categories" className="form-label">
                        Kategorija
                    </label>
                    <ReactSelect
                        required
                        id="categories"
                        name="category"
                        isClearable={false}
                        placeholder="Pasirinkite..."
                        isDisabled={disabled}
                        options={categoriesOptions}
                        defaultValue={categoriesOptions.find(
                            ({ value }) => value === initialData?.categoryId,
                        )}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="length" className="form-label">
                        Trukmė (minutėmis)
                    </label>
                    <input
                        required
                        type="number"
                        step={5}
                        min={15}
                        disabled={disabled}
                        name="length"
                        className="form-control"
                        id="length"
                        defaultValue={
                            initialData?.length
                                ? getMinutesFromLength(initialData.length)
                                : undefined
                        }
                    />
                </div>
            </form>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    setPriceString("");
                    setServiceWorker(null);
                    dispatch({
                        type: ActionType.AddServiceEmployee,
                        serviceEmployee: {
                            workerId: formData.get("workerId") as string,
                            price: Number(formData.get("price") as string),
                        },
                    });
                }}
            >
                <div className="mb-3">
                    <label className="form-label">Darbuotojai</label>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Pavadinimas</th>
                                <th scope="col">Kaina (EUR)</th>
                                <th scope="col">Veiksmai</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.serviceEmployees.map(
                                ({ workerId, price }) => {
                                    const employee = employees.find(
                                        (x) => x.id === workerId,
                                    );
                                    if (!employee) {
                                        return null;
                                    }

                                    return (
                                        <tr>
                                            <td>{employee.displayName}</td>
                                            <td>{price} EUR</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    disabled={disabled}
                                                    onClick={() => {
                                                        const root = createRoot(
                                                            document.getElementById(
                                                                "modal-confirm",
                                                            )!,
                                                        );
                                                        root.render(
                                                            <ModalLayout
                                                                autoFocusCancel
                                                                title="Pašalinti darbuotoją"
                                                                saveTitle="Pašalinti"
                                                                saveClassName="btn-danger"
                                                                cancelTitle="Atšaukti"
                                                                onClose={() =>
                                                                    root.unmount()
                                                                }
                                                                onSave={() => {
                                                                    root.unmount();
                                                                    dispatch({
                                                                        type: ActionType.RemoveServiceEmployee,
                                                                        id: employee.id,
                                                                    });
                                                                }}
                                                            >
                                                                Ar tikrai norite
                                                                pašalinti
                                                                darbuotoją iš
                                                                šios paslaugos
                                                                vykdymo?
                                                            </ModalLayout>,
                                                        );
                                                    }}
                                                >
                                                    Ištrinti
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                            {!!employeeOptions.length ? (
                                <tr>
                                    <td>
                                        <ReactSelect
                                            required
                                            name="workerId"
                                            isClearable={true}
                                            placeholder="Pasirinkite..."
                                            options={employeeOptions}
                                            isDisabled={disabled}
                                            onChange={(newValue) =>
                                                setServiceWorker(newValue)
                                            }
                                            value={serviceWorker}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            step="any"
                                            min={1}
                                            name="price"
                                            className="form-control"
                                            required
                                            disabled={disabled}
                                            value={priceString}
                                            onChange={(event) =>
                                                setPriceString(
                                                    event.currentTarget.value,
                                                )
                                            }
                                        />
                                    </td>
                                    <td>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={disabled}
                                        >
                                            Pridėti
                                        </button>
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                </div>
            </form>
        </ModalLayout>
    );
};
