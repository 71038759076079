import {
  Calendar,
  dateFnsLocalizer,
  View,
  EventProps,
  DateLocalizer,
} from "react-big-calendar";
import * as dates from "date-arithmetic";
import { StartOfWeek } from "date-arithmetic";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { lt } from "date-fns/locale";
import endOfDay from "date-fns/endOfDay";
import addMinutes from "date-fns/addMinutes";
import startOfDay from "date-fns/startOfDay";
import endOfWeek from "date-fns/endOfWeek";
import isWeekend from "date-fns/isWeekend";
import isValid from "date-fns/isValid";
import isPast from "date-fns/isPast";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { createRoot } from "react-dom/client";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import {
  useEffect,
  useReducer,
  Reducer,
  FunctionComponent,
  isValidElement,
  cloneElement,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";

import { MainLayout } from "../components/main-layout/MainLayout";
import { EventModal } from "../components/event-modal/EventModal";
import { Visit, VisitApi } from "../api/Visit";

import { useIsMounted } from "../hooks/useIsMounted";
import { ResponseDto, Status } from "../api/ApiContracts";
import {
  Employee,
  Organization,
  OrganizationInfo,
  UserType,
} from "../api/Organization";
import { Loader } from "../components/loader";
import { ErrorSection } from "../components/error-section/ErrorSection";
import { createErrorModal } from "../helpers/error-helpers";
import { WorkerTime, WorkerTimeApi } from "../api/WorkerTime";
import { resolveVisitTitle } from "../helpers/events-helpers";

import s from "./WeekSchedulePage.module.scss";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

declare module "react-big-calendar" {
  interface EventWrapperProps {
    children: React.ReactNode;
  }
}

interface ExtendedDateLocalizer extends DateLocalizer {
  firstVisibleDay: (date: Date, localizer: DateLocalizer) => Date;
  lastVisibleDay: (date: Date, localizer: DateLocalizer) => Date;
}

const DnDCalendar = withDragAndDrop(Calendar);
const localizer = {
  ...dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: { lt },
  }),
  firstVisibleDay: (date: Date, localizer: DateLocalizer) => {
    var firstOfMonth = dates.startOf(date, "month");
    return dates.startOf(
      firstOfMonth,
      "week",
      localizer.startOfWeek("lt") as StartOfWeek,
    );
  },
  lastVisibleDay: (date: Date, localizer: DateLocalizer) => {
    var endOfMonth = dates.endOf(date, "month");
    return dates.endOf(
      endOfMonth,
      "week",
      localizer.startOfWeek("lt") as StartOfWeek,
    );
  },
} as ExtendedDateLocalizer;

interface Option {
  value: string;
  label: string;
}

interface WorkingTimeSummary {
  [resource: string]: {
    [date: string]: {
      [time: string]: boolean;
    };
  };
}

type CalendarView = "week" | "day" | "month";

interface State {
  events: Visit[];
  organizationInfoResponse: ResponseDto<OrganizationInfo>;
  organizationInfoFetchId: number;
  start: string;
  end: string;
  date: Date;
  areEventsLoading: boolean;
  selectedEmployeeIds: string[];
  workingTimeSummary: WorkingTimeSummary;
  view: CalendarView;
}

enum ActionType {
  AddEvents = "add-event",
  UpdateEvent = "update-event",
  RemoveEvent = "remove-event",
  SetInitialInfo = "set-initial-info-action",
  ReloadInitialInfo = "reload-initial-info-action",
  ExtremesChangedAction = "extremes-change-action",
  SetEventsActions = "set-events-action",
  SetLoadingAction = "set-loading-action",
  SetEmployeesAction = "set-employee-actions",
  SetWorkingTimeSummary = "set-working-time-summary",
}

interface AddEventsAction {
  type: ActionType.AddEvents;
  events: Visit[];
}

interface UpdateEventAction {
  type: ActionType.UpdateEvent;
  event: Visit;
}

interface RemoveEventAction {
  type: ActionType.RemoveEvent;
  id: string;
}

interface SetOrganizationInfoStatusAction {
  type: ActionType.SetInitialInfo;
  responseDto: ResponseDto<OrganizationInfo>;
  visits: Visit[];
  workingTimeSummary: WorkingTimeSummary;
}

interface ReloadOrganizationInfoAction {
  type: ActionType.ReloadInitialInfo;
  date?: Date;
  start?: Date;
  end?: Date;
  view?: CalendarView;
}

interface SetEmployeesAction {
  type: ActionType.SetEmployeesAction;
  ids: string[];
}

interface ExtremesChangedAction {
  type: ActionType.ExtremesChangedAction;
  view: CalendarView;
  start: string;
  end: string;
  date: Date;
}

interface SetEventsAction {
  type: ActionType.SetEventsActions;
  events: Visit[];
  workingTimeSummary: WorkingTimeSummary;
}

interface SetLoadingAction {
  type: ActionType.SetLoadingAction;
  isLoading: boolean;
}

interface SetWorkingTimeSummaryAction {
  type: ActionType.SetWorkingTimeSummary;
  workingTimeSummary: WorkingTimeSummary;
}

type Action =
  | AddEventsAction
  | UpdateEventAction
  | RemoveEventAction
  | SetOrganizationInfoStatusAction
  | ReloadOrganizationInfoAction
  | ExtremesChangedAction
  | SetEventsAction
  | SetLoadingAction
  | SetEmployeesAction
  | SetWorkingTimeSummaryAction;

interface TimeSlotWrapperParams {
  children: React.ReactNode;
  resource?: string;
  value: Date;
}

const minDate = new Date(1972, 0, 1, 6, 0, 0, 0);
const maxDate = new Date(1972, 0, 1, 22, 0, 0, 0);

const resolveInitialDate = (dateString: string | null) => {
  const now = new Date();

  if (!dateString) {
    return now;
  }

  const date = new Date(dateString);

  return isValid(date) ? date : now;
};

const resolveInitialView = (view: string | null): CalendarView => {
  if (!view) {
    return DEFAULT_CALENDAR_VIEW;
  }

  return ["week", "day", "month"].includes(view)
    ? (view as CalendarView)
    : DEFAULT_CALENDAR_VIEW;
};

const resolveRangeExtremes = (date: Date, view: View) => {
  switch (view) {
    default:
    case "week": {
      return {
        start: startOfWeek(date, { weekStartsOn: 1 }),
        end: endOfWeek(date, { weekStartsOn: 1 }),
      };
    }
    case "day": {
      return {
        start: startOfDay(date),
        end: endOfDay(date),
      };
    }
    case "month": {
      return {
        start: localizer.firstVisibleDay(date, localizer),
        end: localizer.lastVisibleDay(date, localizer),
      };
    }
  }
};

const resolveWorkingTimeSummary = async (
  visibleEmployeeIds: string[],
  start: string,
  end: string,
  abortController?: AbortController,
): Promise<WorkingTimeSummary> => {
  try {
    const workingTimesOfAllEmployees = await WorkerTimeApi.search(
      undefined,
      format(new Date(start), "y-MM-dd"),
      format(new Date(end), "y-MM-dd"),
      {
        signal: abortController?.signal,
      },
    );

    const workingTimesByEmployees = Object.values(
      workingTimesOfAllEmployees.reduce<{
        [key: string]: WorkerTime[];
      }>((result, workerTime) => {
        if (!visibleEmployeeIds.includes(workerTime.workerId)) {
          return result;
        }

        if (result[workerTime.workerId]) {
          result[workerTime.workerId].push(workerTime);
        } else {
          result[workerTime.workerId] = [workerTime];
        }

        return result;
      }, {}),
    );

    return workingTimesByEmployees.reduce<WorkingTimeSummary>(
      (result, workingTimeOfTheWorker) => {
        workingTimeOfTheWorker.forEach((workingTime) => {
          const endDate = new Date(`${workingTime.date}T${workingTime.end}`);

          if (result[workingTime.workerId]) {
            if (result[workingTime.workerId][workingTime.date]) {
              for (
                let start = new Date(
                  `${workingTime.date}T${workingTime.start}`,
                );
                start < endDate;
                start = addMinutes(start, 15)
              ) {
                result[workingTime.workerId][workingTime.date][
                  format(start, "HH:mm:ss")
                ] = true;
              }
            } else {
              const activeSlots: {
                [time: string]: boolean;
              } = {};

              for (
                let start = new Date(
                  `${workingTime.date}T${workingTime.start}`,
                );
                start < endDate;
                start = addMinutes(start, 15)
              ) {
                activeSlots[format(start, "HH:mm:ss")] = true;
              }

              result[workingTime.workerId][workingTime.date] = activeSlots;
            }
          } else {
            const activeSlots: {
              [time: string]: boolean;
            } = {};
            for (
              let start = new Date(`${workingTime.date}T${workingTime.start}`);
              start < endDate;
              start = addMinutes(start, 15)
            ) {
              activeSlots[format(start, "HH:mm:ss")] = true;
            }

            result[workingTime.workerId] = {
              [workingTime.date]: activeSlots,
            };
          }
        });

        return result;
      },
      {},
    );
  } catch (error) {
    console.error(error);
    return {};
  }
};

const decorateVisitsWithTitles = (
  visits: Visit[],
  organizationInfo: OrganizationInfo,
) => {
  return visits.map((visit) => {
    const service = organizationInfo.serviceList.find(
      (service) => service.id === visit.serviceId,
    );
    const serviceNamePart = service?.name;
    const clientNamePart = visit.client?.name;
    return {
      ...visit,
      title: resolveVisitTitle(clientNamePart, serviceNamePart),
    };
  });
};

const DayAndWeekEventView: FunctionComponent<EventProps<Visit>> = ({
  title,
  event,
}) => {
  const { start } = event as Visit;
  return (
    <div>
      <strong className={s.eventSlotTimePrefix}>{`${format(
        start,
        "HH:mm",
      )}: `}</strong>
      {title}
    </div>
  );
};

export const DEFAULT_CALENDAR_VIEW = "week";

export const WeekSchedulePage = () => {
  const isMounted = useIsMounted();
  const isInitialized = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, dispatch] = useReducer<Reducer<State, Action>, State>(
    (state, action) => {
      switch (action.type) {
        case ActionType.AddEvents: {
          return {
            ...state,
            events: [...state.events, ...action.events],
          };
        }
        case ActionType.UpdateEvent: {
          return {
            ...state,
            events: state.events.reduce<Visit[]>((newEvents, event) => {
              if (event.id === action.event.id) {
                newEvents.push(action.event);
              } else {
                newEvents.push(event);
              }

              return newEvents;
            }, []),
            areEventsLoading: false,
          };
        }
        case ActionType.RemoveEvent: {
          return {
            ...state,
            events: [...state.events.filter(({ id }) => action.id !== id)],
          };
        }
        case ActionType.SetInitialInfo: {
          const workerList =
            action.responseDto.value?.workerList.filter(({ userType }) =>
              [UserType.Worker, UserType.ManagerWorker].includes(userType),
            ) || [];
          const initialSelectedEmployeeIdsString =
            searchParams.get("workerIds");
          const initialSelectedEmployeeIds =
            initialSelectedEmployeeIdsString?.split(",") || [];
          const initialSelectedEmployees = workerList.reduce<Employee[]>(
            (result, employee) => {
              if (initialSelectedEmployeeIds.includes(employee.id)) {
                result.push(employee);
              }

              return result;
            },
            [],
          );

          const selectedEmployeeIds =
            workerList.length === 1
              ? [workerList[0].id]
              : initialSelectedEmployees.length
              ? initialSelectedEmployees.map(({ id }) => id)
              : [workerList[0].id];

          setSearchParams(
            {
              workerIds: selectedEmployeeIds.join(","),
              view: state.view,
              date: state.date.toISOString(),
            },
            { replace: true },
          );

          return {
            ...state,
            selectedEmployeeIds,
            organizationInfoResponse: action.responseDto,
            events: action.visits,
            workingTimeSummary: action.workingTimeSummary,
          };
        }
        case ActionType.ReloadInitialInfo: {
          return {
            ...state,
            organizationInfoFetchId: state.organizationInfoFetchId + 1,
            date: action.date ?? state.date,
            start: action.start ? action.start.toISOString() : state.start,
            end: action.end ? action.end.toISOString() : state.end,
            view: action.view ?? state.view,
          };
        }
        case ActionType.SetEmployeesAction: {
          const newParams = new URLSearchParams(searchParams.toString());

          newParams.set("workerIds", action.ids.join(","));

          setSearchParams(newParams, { replace: true });

          return {
            ...state,
            selectedEmployeeIds: action.ids,
          };
        }
        case ActionType.ExtremesChangedAction: {
          const newParams = new URLSearchParams(searchParams.toString());
          newParams.set("date", action.date.toISOString());
          newParams.set("view", action.view);
          setSearchParams(newParams, { replace: true });

          return {
            ...state,
            date: action.date,
            start: action.start,
            end: action.end,
            view: action.view,
            areEventsLoading: true,
          };
        }
        case ActionType.SetEventsActions: {
          return {
            ...state,
            events: action.events,
            workingTimeSummary: action.workingTimeSummary,
            areEventsLoading: false,
          };
        }
        case ActionType.SetLoadingAction: {
          return {
            ...state,
            areEventsLoading: action.isLoading,
          };
        }
        case ActionType.SetWorkingTimeSummary: {
          return {
            ...state,
            workingTimeSummary: action.workingTimeSummary,
          };
        }
      }
    },
    {
      events: [],
      organizationInfoResponse: {
        status: Status.Pending,
        value: undefined,
      },
      organizationInfoFetchId: 0,
      view: resolveInitialView(searchParams.get("view")),
      date: resolveInitialDate(searchParams.get("date")),
      // Resolve in init.
      start: "",
      end: "",
      areEventsLoading: false,
      workingTimeSummary: {},
      selectedEmployeeIds: [],
    },
    (state): State => {
      const { start, end } = resolveRangeExtremes(state.date, state.view);

      return {
        ...state,
        start: start.toISOString(),
        end: end.toISOString(),
      };
    },
  );
  // Store reference of the state value for the components that are cached.
  const workingTimeSummaryRef = useRef(state.workingTimeSummary);
  workingTimeSummaryRef.current = state.workingTimeSummary;

  useEffect(() => {
    const abortController = new AbortController();
    const loadData = async () => {
      try {
        const [organizationInfo, visits] = await Promise.all([
          Organization.InsideOrganizationInfo({
            signal: abortController.signal,
          }),
          VisitApi.search([], state.start, state.end, {
            signal: abortController.signal,
          }),
        ]);

        if (!isMounted()) {
          return;
        }

        // Employees that are eligible for picking them to show schedules in the calendar.
        const selectableEmployeeIds = organizationInfo.workerList.reduce<
          string[]
        >((result, { id, userType }) => {
          if ([UserType.Worker, UserType.ManagerWorker].includes(userType)) {
            result.push(id);
          }
          return result;
        }, []);

        dispatch({
          type: ActionType.SetInitialInfo,
          responseDto: {
            value: organizationInfo,
            status: Status.Loaded,
          },
          workingTimeSummary: await resolveWorkingTimeSummary(
            selectableEmployeeIds,
            state.start,
            state.end,
            abortController,
          ),
          visits: decorateVisitsWithTitles(visits, organizationInfo),
        });
      } catch (error) {
        if (
          error instanceof DOMException &&
          (error.name === "AbortError" || error.code === 20 || !isMounted())
        ) {
          return;
        }

        console.error(error);
        dispatch({
          type: ActionType.SetInitialInfo,
          responseDto: {
            value: undefined,
            status: Status.Error,
          },
          visits: [],
          workingTimeSummary: {},
        });
      }
    };
    loadData();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.organizationInfoFetchId]);

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      return;
    }

    if (!searchParams.toString()) {
      const date = resolveInitialDate(null);
      const view = resolveInitialView(null);
      const { start, end } = resolveRangeExtremes(date, view);
      dispatch({
        // Reset to the starting point.
        type: ActionType.ReloadInitialInfo,
        date,
        view,
        start,
        end,
      });
      loadEventsByExtremes(start.toISOString(), end.toISOString());
    }
  }, [searchParams]);

  const pageUnmountController = new AbortController();
  useEffect(() => {
    return () => pageUnmountController.abort();
  }, []);

  if (state.organizationInfoResponse.status === Status.Pending) {
    return (
      <MainLayout className={s.content}>
        <Loader />
      </MainLayout>
    );
  }

  if (state.organizationInfoResponse.status === Status.Error) {
    return (
      <MainLayout className={s.content}>
        <ErrorSection
          onRetryClick={() =>
            // Reload the state as it failed.
            dispatch({ type: ActionType.ReloadInitialInfo })
          }
        />
      </MainLayout>
    );
  }

  const loadEventsByExtremes = async (start: string, end: string) => {
    try {
      const events = await VisitApi.search([], start, end, {
        signal: pageUnmountController.signal,
      });
      // Employees that are eligible for picking them to show schedules in the calendar.
      const selectableEmployeeIds =
        state.organizationInfoResponse.value?.workerList.reduce<string[]>(
          (result, { id, userType }) => {
            if ([UserType.Worker, UserType.ManagerWorker].includes(userType)) {
              result.push(id);
            }
            return result;
          },
          [],
        ) || [];

      dispatch({
        type: ActionType.SetEventsActions,
        events: state.organizationInfoResponse.value
          ? decorateVisitsWithTitles(
              events,
              state.organizationInfoResponse.value,
            )
          : events,
        workingTimeSummary: await resolveWorkingTimeSummary(
          selectableEmployeeIds,
          start,
          end,
        ),
      });
    } catch (error) {
      // TODO: add toast...
      if (
        window.confirm(
          "Nepavyko užkrauti rezervacijų. Ar norėtumėte pabandyti dar kartą?",
        )
      ) {
        loadEventsByExtremes(start, end);
      }
    }
  };

  const startDate = new Date(state.start);
  const isDayView = startDate.getDay() === new Date(state.end).getDay();
  const isWeekendDayView = isDayView && isWeekend(startDate);
  const employeeOptions =
    state.organizationInfoResponse.value.workerList.reduce<Option[]>(
      (result, worker) => {
        if (
          [UserType.ManagerWorker, UserType.Worker].includes(worker.userType)
        ) {
          result.push({
            value: worker.id,
            label: worker.displayName,
          });
        }

        return result;
      },
      [],
    );

  const isMultiWorkerList = employeeOptions.length > 1;
  const filteredEvents =
    state.view === "month"
      ? state.events.filter(({ workerId }) =>
          state.selectedEmployeeIds.includes(workerId),
        )
      : state.events;
  const selectedWorkers =
    state.organizationInfoResponse.value.workerList.reduce<Employee[]>(
      (result, employee) => {
        if (state.selectedEmployeeIds.includes(employee.id)) {
          result.push(employee);
        }
        return result;
      },
      [],
    );

  return (
    <MainLayout className={s.content}>
      <div className={classNames("px-3 py-2", s.header)}>
        {isMultiWorkerList ? (
          <div className={s.workerSelectContainer}>
            <label htmlFor="worker-filter">Rodyti darbuotojų grafikus:</label>
            <div>
              <Select
                isMulti
                isClearable={false}
                isDisabled={employeeOptions.length === 1}
                className="form-control-plaintext"
                id="worker-filter"
                placeholder="Visi atliekantys paslaugą"
                options={employeeOptions}
                value={employeeOptions.reduce<Option[]>((result, item) => {
                  if (state.selectedEmployeeIds.includes(item.value)) {
                    result.push(item);
                  }
                  return result;
                }, [])}
                onChange={(employeeOptions) => {
                  dispatch({
                    type: ActionType.SetEmployeesAction,
                    ids: employeeOptions.map(({ value }) => value),
                  });
                }}
                noOptionsMessage={() => "Nėra pasirinkimo"}
                styles={{
                  menu: (provided) => {
                    return { ...provided, zIndex: 15 };
                  },
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {state.selectedEmployeeIds.length > 0 ? (
        <DnDCalendar
          selectable
          culture="lt"
          step={15}
          date={state.date}
          localizer={localizer}
          events={filteredEvents}
          resources={selectedWorkers}
          className={classNames(s.calendar, {
            [s.dayView]: isDayView,
            [s.weekend]: isWeekendDayView,
            [s.weekView]: state.view === "week",
          })}
          onSelectEvent={(rawVisit) => {
            const visit = rawVisit as Visit;
            const root = createRoot(document.getElementById("modal")!);

            const service =
              state.organizationInfoResponse.value?.serviceList.find(
                (service) => service.id === visit.serviceId,
              );

            if (!service) {
              createErrorModal({
                message: "Nepavyko rasti vizitui priskirtos paslaugos.",
              });
              return;
            }

            const employee =
              state.organizationInfoResponse.value?.workerList.find(
                (employee) => employee.id === visit.workerId,
              );
            if (!employee) {
              createErrorModal({
                message:
                  "Nepavyko rasti darbuotojo, kuriam priskirta paslauga.",
              });
              return;
            }

            root.render(
              <EventModal
                initialData={visit}
                serviceList={state.organizationInfoResponse.value?.serviceList}
                workerList={state.organizationInfoResponse.value?.workerList}
                onClose={() => root.unmount()}
                onRemove={async (setDisabed, setError) => {
                  try {
                    setDisabed();
                    await VisitApi.delete(visit.id, {
                      signal: pageUnmountController.signal,
                    });
                    root.unmount();

                    if (pageUnmountController.signal.aborted) {
                      return;
                    }

                    dispatch({
                      type: ActionType.RemoveEvent,
                      id: visit.id,
                    });
                  } catch (error) {
                    console.error(error);

                    if (pageUnmountController.signal.aborted) {
                      return;
                    }

                    setError(
                      "Nepavyko ištrinti vizito įrašo. Prašome bandyti vėliau.",
                    );
                  }
                }}
                onSave={async (
                  newVisit,
                  // Not used for update...
                  _additionalService,
                  setDisabed,
                  setError,
                ) => {
                  const service =
                    state.organizationInfoResponse.value?.serviceList.find(
                      (service) => service.id === newVisit.serviceId,
                    );

                  if (!service) {
                    setError("Nepavyko surasti su vizitu susietos paslaugos.");
                    return;
                  }

                  const serviceWorker = service?.workerIdList.find(
                    (workerDto) => workerDto.workerId === newVisit.workerId,
                  );

                  if (!serviceWorker) {
                    setError(
                      "Nepavyko nustatyti paslaugos kainos. Vizitui priskirtas darbuotojas nevykdo šios paslaugos.",
                    );
                    return;
                  }

                  try {
                    setDisabed();
                    await VisitApi.updateDetails(
                      newVisit.id,
                      {
                        price: serviceWorker.price,
                        skipClientSmsNotification: !!newVisit.skipNotification,
                        client: {
                          name: newVisit.client?.name || "",
                          phone: newVisit.client?.phone || "",
                        },
                      },
                      {
                        signal: pageUnmountController.signal,
                      },
                    );
                    if (visit.start.getTime() !== newVisit.start.getTime()) {
                      await VisitApi.updateTime(
                        newVisit.id,
                        {
                          id: newVisit.id,
                          start: newVisit.start,
                          end: newVisit.end,
                        },
                        {
                          signal: pageUnmountController.signal,
                        },
                      );
                    }
                    root.unmount();

                    if (pageUnmountController.signal.aborted) {
                      return;
                    }

                    dispatch({
                      type: ActionType.UpdateEvent,
                      event: newVisit,
                    });
                  } catch (error) {
                    console.error(error);
                    if (pageUnmountController.signal.aborted) {
                      return;
                    }

                    setError(
                      "Nepavyko atnaujinti įrašo. Prašome bandyti vėliau.",
                    );
                  }
                }}
              />,
            );
          }}
          // TODO: Adjust by working time...
          min={minDate}
          max={maxDate}
          onSelectSlot={({ start, end, resourceId }) => {
            if (state.view === "month") {
              return;
            }

            const startDate = format(start, "y-MM-dd");
            const startTime = format(start, "HH:mm:ss");
            const isSlotActive =
              state.workingTimeSummary[resourceId as string]?.[startDate]?.[
                startTime
              ] && !isPast(start);

            if (!isSlotActive) {
              console.info("Slot is not allowed.");
              return;
            }

            const endDate = new Date(end);

            // TODO: Check if needed.
            // const canFitTheSlot = state.events.every((event) => {
            //     if (
            //         event.start.getDate() !== start.getDate() ||
            //         event.workerId !== state.selectedEmployeeId
            //     ) {
            //         return true;
            //     }

            //     const startClashes =
            //         start >= event.start && start <= event.end;
            //     const endClashes =
            //         endDate >= event.start && endDate <= event.end;
            //     return !(startClashes || endClashes);
            // });
            // if (!canFitTheSlot) {
            //     return;
            // }

            const root = createRoot(document.getElementById("modal")!);

            const employee =
              state.organizationInfoResponse.value?.workerList.find(
                (employee) => employee.id === resourceId,
              );

            if (!employee) {
              createErrorModal({
                message:
                  "Nepavyko rasti darbuotojo, kuriam priskirta paslauga.",
              });
              return;
            }

            root.render(
              <EventModal
                initialData={{
                  start,
                  end: endDate,
                }}
                serviceList={state.organizationInfoResponse.value?.serviceList}
                workerList={state.organizationInfoResponse.value?.workerList}
                onClose={() => root.unmount()}
                onSave={async (
                  visit,
                  additionalVisit,
                  setDisabled,
                  setError,
                ) => {
                  if (!state.organizationInfoResponse.value?.id) {
                    return;
                  }

                  try {
                    setDisabled();
                    const primaryEvent = {
                      ...visit,
                      id: uuid(),
                    };
                    const additionalEvent = additionalVisit
                      ? {
                          ...additionalVisit,
                          id: uuid(),
                        }
                      : undefined;
                    await VisitApi.create(
                      {
                        organizationId:
                          state.organizationInfoResponse.value?.id,
                        id: primaryEvent.id,
                        start: `${format(visit.start, "y-MM-dd")}T${format(
                          visit.start,
                          "HH:mm",
                        )}`,
                        client: {
                          name: primaryEvent.client?.name || "",
                          phone: primaryEvent.client?.phone || "",
                        },
                        skipClientSmsNotification:
                          !!primaryEvent.skipNotification,
                        serviceWorkerMap: [
                          {
                            serviceId: primaryEvent.serviceId,
                            workerId: primaryEvent.workerId,
                          },
                          ...(additionalEvent
                            ? [
                                {
                                  serviceId: additionalEvent.serviceId,
                                  workerId: additionalEvent.workerId,
                                },
                              ]
                            : []),
                        ],
                      },
                      {
                        signal: pageUnmountController.signal,
                      },
                    );
                    root.unmount();
                    if (pageUnmountController.signal.aborted) {
                      return;
                    }
                    dispatch({
                      type: ActionType.AddEvents,
                      events: additionalEvent
                        ? [primaryEvent, additionalEvent]
                        : [primaryEvent],
                    });
                  } catch (error) {
                    console.error(error);
                    if (pageUnmountController.signal.aborted) {
                      return;
                    }
                    setError(
                      "Nepavyko pridėti vizito. Prašome bandyti vėliau.",
                    );
                  }
                }}
              />,
            );
          }}
          onEventResize={async ({ event, start, end }) => {
            try {
              const visit = {
                ...(event as Visit),
                start: start as Date,
                end: end as Date,
              };
              dispatch({
                type: ActionType.SetLoadingAction,
                isLoading: true,
              });
              // TODO: improve UX of the event update (currently flickers back and forth)...
              await VisitApi.updateTime(
                visit.id,
                {
                  id: visit.id,
                  start: visit.start,
                  end: visit.end,
                },
                {
                  signal: pageUnmountController.signal,
                },
              );

              if (pageUnmountController.signal.aborted) {
                return;
              }

              dispatch({
                type: ActionType.UpdateEvent,
                event: visit,
              });
            } catch (error) {
              dispatch({
                type: ActionType.SetLoadingAction,
                isLoading: false,
              });
              console.error(error);
              if (pageUnmountController.signal.aborted) {
                return;
              }
              createErrorModal({
                message:
                  "Nepavyko atnaujinti vizito laiko. Prašome bandyti vėliau.",
              });
            }
          }}
          onNavigate={(date, currentView, action) => {
            const view = action === "DATE" ? "day" : currentView;
            const { start, end } = resolveRangeExtremes(date, view);

            dispatch({
              type: ActionType.ExtremesChangedAction,
              view: view as CalendarView,
              start: start.toISOString(),
              end: end.toISOString(),
              date,
            });

            loadEventsByExtremes(start.toISOString(), end.toISOString());
          }}
          onView={(view) => {
            const { start, end } = resolveRangeExtremes(state.date, view);

            dispatch({
              type: ActionType.ExtremesChangedAction,
              view: view as CalendarView,
              date: state.date,
              start: start.toISOString(),
              end: end.toISOString(),
            });

            loadEventsByExtremes(start.toISOString(), end.toISOString());
          }}
          onEventDrop={async (args) => {
            const { event, start, end, resourceId } = args as {
              event: Visit;
              start: Date;
              end: Date;
              isAllDay: boolean;
              resourceId: string;
            };

            const minDateToday = new Date(start);
            minDateToday.setHours(minDate.getHours());
            minDateToday.setMinutes(minDate.getMinutes());

            const maxDateToday = new Date(start);
            maxDateToday.setHours(maxDate.getHours());
            maxDateToday.setMinutes(maxDate.getMinutes());

            let startDate = new Date(start as Date);
            if (startDate < minDateToday) {
              startDate = minDateToday;
            }

            let endDate = new Date(end as Date);
            if (endDate > maxDateToday) {
              endDate = maxDateToday;
            }

            try {
              // Prevent the calendar day shift
              startDate.setDate(event.start.getDate());
              endDate.setDate(event.end.getDate());

              const visit = {
                ...(event as Visit),
                start: startDate,
                end: endDate,
                workerId: resourceId,
              };
              dispatch({
                type: ActionType.SetLoadingAction,
                isLoading: true,
              });
              // TODO: improve UX of the event update (currently flickers back and forth)...
              await VisitApi.updateTime(
                visit.id,
                {
                  id: visit.id,
                  start: visit.start,
                  end: visit.end,
                },
                {
                  signal: pageUnmountController.signal,
                },
              );

              if (pageUnmountController.signal.aborted) {
                return;
              }

              dispatch({
                type: ActionType.UpdateEvent,
                event: visit,
              });
            } catch (error) {
              dispatch({
                type: ActionType.SetLoadingAction,
                isLoading: false,
              });
              console.error(error);
              if (pageUnmountController.signal.aborted) {
                return;
              }
              createErrorModal({
                message:
                  "Nepavyko atnaujinti vizito laiko. Prašome bandyti vėliau.",
              });
            }
          }}
          views={["week", "day", "month"]}
          view={state.view}
          defaultView="week"
          timeslots={4}
          resourceIdAccessor={(worker) => (worker as Employee).id}
          resourceTitleAccessor={(worker) => (worker as Employee).displayName}
          resourceAccessor={(rawVisit) => {
            const visit = rawVisit as Visit;
            return visit.workerId;
          }}
          messages={{
            date: "Data",
            time: "Laikas",
            event: "Įvykis",
            allDay: "Visą dieną",
            week: "Savaitė",
            work_week: "Darbo savaitė",
            day: "Diena",
            month: "Mėnuo",
            previous: "Atgal",
            next: "Pirmyn",
            yesterday: "Vakar",
            tomorrow: "Rytoj",
            today: "Šiandien",
            agenda: "Tvarkaraštis",
            showMore: (count: number) => `${count} daugiau`,
            noEventsInRange: "Nėra įvykių",
          }}
          components={{
            day: {
              event: DayAndWeekEventView as FunctionComponent<
                EventProps<object>
              >,
            },
            week: {
              event: DayAndWeekEventView as FunctionComponent<
                EventProps<object>
              >,
            },
            timeSlotWrapper: (({
              resource,
              value,
              children,
            }: TimeSlotWrapperParams) => {
              if (!resource || !isValidElement(children)) {
                return children;
              }

              const date = format(value, "y-MM-dd");
              const time = format(value, "HH:mm:ss");

              const isSlotActive =
                !!workingTimeSummaryRef.current[resource]?.[date]?.[time] &&
                !isPast(value);

              const clonedChildren = cloneElement(children, {
                ...children.props,
                className: classNames(children.props.className, {
                  [s.inactiveSlot]: !isSlotActive,
                }),
              });
              return clonedChildren;
            }) as React.ComponentType<{}>,
          }}
        />
      ) : (
        <div className="px-3 py-2">
          Pasirinkite darbuotojus, kurių grafikus norite matyti.
        </div>
      )}
      {state.areEventsLoading ? (
        <div className={s.overlayLoader}>
          <Loader />
        </div>
      ) : null}
    </MainLayout>
  );
};
