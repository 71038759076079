import format from "date-fns/format";
import addMinutes from "date-fns/addMinutes";
import setMilliseconds from "date-fns/setMilliseconds";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import setHours from "date-fns/setHours";
import isEqual from "date-fns/isEqual";
import { createRoot } from "react-dom/client";
import { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import SelectComponent from "react-select";
import Select from "react-select/dist/declarations/src/Select";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import TypeaheadClass from "react-bootstrap-typeahead/types/core/Typeahead";
import { Option as TypeaheadOption } from "react-bootstrap-typeahead/types/types";
import { ModalLayout } from "../modal/ModalLayout";
import { DatePicker } from "../date-picker";
import { Employee, Service } from "../../api/Organization";
import { Visit } from "../../api/Visit";
import { createErrorModal } from "../../helpers/error-helpers";
import { Option, Group } from "../react-select/ReactSelect";
import { Loader } from "../loader";
import { ResponseDto, Status } from "../../api/ApiContracts";
import { VisitApi } from "../../api/Visit";
import { ORGANIZATION_ID } from "../../api/api-config";
import { Client } from "../../api/Client";
import {
  getHumanReadableTimeTitle,
  getMinutesFromLength,
} from "../../helpers/time-helpers";
import { resolveVisitTitle } from "../../helpers/events-helpers";
import "react-bootstrap-typeahead/css/Typeahead.css";
import s from "./EventModal.module.css";

const getTargetDay = (date: Date) => {
  const baseDay = setHours(new Date(date), 0);
  return setMilliseconds(
    setSeconds(
      setMinutes(addMinutes(baseDay, -1 * baseDay.getTimezoneOffset()), 0),
      0,
    ),
    0,
  ).toISOString();
};

export const getNearest15MinDate = (date: string, time: string) => {
  // Take nearest 15 min.
  const [hours, minutes, seconds] = time.split(":");
  const minutesNumber = Number(minutes);
  const remainderOf15 = minutesNumber % 15;
  // Put the minutes to nearest 15 minutes (in case on point - leave as is).
  const nearestSelectableSlotMinutes =
    remainderOf15 === 0 ? minutesNumber : minutesNumber + (15 - remainderOf15);

  return new Date(
    `${date}T${
      nearestSelectableSlotMinutes === 60 ? Number(hours) + 1 : hours
    }:${(nearestSelectableSlotMinutes === 60 ? 0 : nearestSelectableSlotMinutes)
      .toString()
      .padStart(2, "0")}:${seconds}`,
  );
};

interface Props {
  initialData?: Partial<Visit>;
  serviceList?: Service[];
  workerList?: Employee[];
  onClose: () => void;
  onSave: (
    visit: Visit,
    additionalVisit: Visit | undefined,
    setDisabled: () => void,
    setError: (message: string) => void,
  ) => void;
  onRemove?: (
    setDisabed: () => void,
    setError: (message: string) => void,
  ) => void;
}

export const EventModal = ({
  initialData,
  serviceList,
  workerList,
  onClose,
  onSave,
  onRemove,
}: Props) => {
  const [disabled, setDisabled] = useState(false);
  const [availabilityFetchId, setAvailabilityFetchId] = useState(0);
  const [availabilityResponse, setAvailabilityResponse] = useState<
    ResponseDto<
      | {
          availableTimesByDate: Record<string, Date[]>;
          availableDates: Date[];
        }
      | undefined
    >
  >({
    value: undefined,
    status: Status.Loaded,
  });
  // Initial selectedDate is set when first availability request is completed.
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [showSecondaryService, setShowSecondaryService] = useState(false);
  const [selectedPrimaryServiceId, setSelectedPrimaryServiceId] = useState<
    string | undefined
  >(initialData?.serviceId);
  const [selectedWorkerId, setSelectedWorkerId] = useState<string | undefined>(
    initialData?.workerId,
  );
  const [selectedSecondaryServiceId, setSelectedSecondaryServiceId] = useState<
    string | undefined
  >(undefined);
  const [hasReservationEndChanged, setHasReservationEndChanged] =
    useState(false);
  const [isPhoneSearchPending, setIsPhoneSearchPending] = useState(false);
  const [isNameSearchPending, setIsNameSearchPending] = useState(false);
  const [clientByPhone, setClientsByPhone] = useState<Client[]>([]);
  const [clientByName, setClientsByName] = useState<Client[]>([]);
  // The `selectedPhone` and `selectedName` are only used for controlling input selected value.
  // These should not be use for the submission.
  const [selectedPhone, setSelectedPhone] = useState<TypeaheadOption[]>(
    initialData?.client?.phone ? [initialData.client.phone] : [],
  );
  const [selectedName, setSelectedName] = useState<TypeaheadOption[]>(
    initialData?.client?.name ? [initialData.client.name] : [],
  );
  const nameInputRef = useRef<TypeaheadClass>(null);
  const phoneInputRef = useRef<TypeaheadClass>(null);
  const secondaryServiceSelectRef = useRef<Select<Option, false, Group> | null>(
    null,
  );
  const formId = "event-form";
  const isEdit = !!initialData?.id;

  useEffect(() => {
    const abortController = new AbortController();
    const loadAvailability = async () => {
      if (!selectedPrimaryServiceId || !selectedWorkerId) {
        setAvailabilityResponse({
          status: Status.Loaded,
          value: undefined,
        });
        setSelectedDate(undefined);
        return;
      }

      try {
        setAvailabilityResponse({ status: Status.Pending });
        setSelectedDate(undefined);
        const response = await VisitApi.availability(
          {
            organizationId: ORGANIZATION_ID,
            serviceWorkerMap: [
              {
                workerId: selectedWorkerId,
                serviceId: selectedPrimaryServiceId,
              },
              ...(selectedSecondaryServiceId
                ? [
                    {
                      workerId: selectedWorkerId,
                      serviceId: selectedSecondaryServiceId,
                    },
                  ]
                : []),
            ],
          },
          {
            signal: abortController.signal,
          },
        );

        const calculatedResponse = response.daysAvailabilities.reduce<{
          availableDates: Date[];
          availableTimesByDate: Record<string, Date[]>;
        }>(
          ({ availableDates, availableTimesByDate }, { date, slots }) => {
            const ongoingDate = new Date(date);

            availableDates.push(ongoingDate);
            const availableTimes = slots.reduce<Date[]>(
              (times, { start, end }) => {
                const startDate = getNearest15MinDate(date, start);
                const endDate = new Date(`${date}T${end}`);
                let i = new Date(startDate);
                while (i <= endDate) {
                  times.push(i);
                  i = addMinutes(i, 15);
                }
                return times;
              },
              [],
            );
            availableTimesByDate[ongoingDate.toISOString()] = availableTimes;
            return { availableDates, availableTimesByDate };
          },
          {
            availableDates: [],
            availableTimesByDate: {},
          },
        );

        // If the event that is already created is selected - add it's start time to availabilities
        // We distinguesh the event that is already created if there's an `id` placed in `initialData`.
        const dateOfAlreadyCreatedVisit = initialData?.id
          ? initialData.start
          : undefined;
        if (dateOfAlreadyCreatedVisit) {
          // Append to `availableDates`
          calculatedResponse.availableDates.push(
            new Date(format(dateOfAlreadyCreatedVisit, "yyyy-MM-dd")),
          );
          // Append to `availableTimesByDate`
          const targetDay = getTargetDay(dateOfAlreadyCreatedVisit);
          // If no times available at the date - add that single time.
          if (calculatedResponse.availableTimesByDate[targetDay]) {
            calculatedResponse.availableTimesByDate[targetDay].push(
              dateOfAlreadyCreatedVisit,
            );
          } else {
            calculatedResponse.availableTimesByDate[targetDay] = [
              dateOfAlreadyCreatedVisit,
            ];
          }
        }
        setAvailabilityResponse({
          status: Status.Loaded,
          value: calculatedResponse,
        });
        // If date is already selected
        //  Try to pick if it if available for selection.
        //      If not available for selection pick first available date (with fallback to current date - edge case if nothing to select).
        // If no date is yet selected
        //  Try to check if the initialDate.start is possible to preselect
        //      If not possible to preselect pick first available date (with fallback to current date - edge case if nothing to select).
        const fallbackSelection =
          calculatedResponse.availableTimesByDate[
            calculatedResponse.availableDates[0]?.toISOString()
          ]?.[0] || new Date();
        const baseSelectedDate = selectedDate
          ? selectedDate
          : initialData?.start;
        const targetDay = baseSelectedDate
          ? getTargetDay(baseSelectedDate)
          : undefined;
        const targetDate =
          targetDay && baseSelectedDate
            ? calculatedResponse.availableTimesByDate[targetDay]?.find((date) =>
                isEqual(date, baseSelectedDate),
              )
            : undefined;
        setSelectedDate(targetDate || fallbackSelection);
      } catch (error) {
        console.error(error);
        setAvailabilityResponse({
          value: undefined,
          status: Status.Error,
        });
      }
    };
    loadAvailability();

    return () => {
      abortController.abort();
    };
  }, [
    availabilityFetchId,
    selectedPrimaryServiceId,
    selectedWorkerId,
    selectedSecondaryServiceId,
  ]);

  const includeDates = availabilityResponse.value?.availableDates || [];
  const includeTimes = selectedDate
    ? availabilityResponse.value?.availableTimesByDate[
        new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          (-1 * selectedDate.getTimezoneOffset()) / 60,
          0,
          0,
        ).toISOString()
      ]
    : [];
  const primaryService = serviceList?.find(
    ({ id }) => id === selectedPrimaryServiceId,
  );
  const secondaryService = serviceList?.find(
    ({ id }) => id === selectedSecondaryServiceId,
  );

  const primaryVisitEnd =
    selectedDate && primaryService
      ? addMinutes(selectedDate, getMinutesFromLength(primaryService.length))
      : undefined;
  const secondaryVisitEnd =
    selectedDate && primaryService && secondaryService
      ? addMinutes(
          selectedDate,
          getMinutesFromLength(primaryService.length) +
            getMinutesFromLength(secondaryService.length),
        )
      : undefined;
  const reservationEnd =
    isEdit && !hasReservationEndChanged
      ? initialData.end
      : secondaryVisitEnd || primaryVisitEnd;
  const primaryServiceOptions =
    serviceList
      ?.map(({ id, name, length }) => ({
        value: id,
        label: `${name} (${getHumanReadableTimeTitle(length)})`,
      }))
      .sort((valueA, valueB) => valueA.label.localeCompare(valueB.label)) || [];
  const workerListOptions = serviceList
    ?.find(({ id }) => id === selectedPrimaryServiceId)
    ?.workerIdList.map(({ workerId }) => {
      return {
        value: workerId,
        label: workerList?.find(({ id }) => id === workerId)?.displayName || "",
      };
    })
    .sort((valueA, valueB) => valueA.label.localeCompare(valueB.label));
  const secondaryServiceOptions = serviceList
    ?.reduce<Option[]>((options, { id, name, length, workerIdList }) => {
      if (
        selectedPrimaryServiceId === id ||
        !workerIdList.some(({ workerId }) => workerId === selectedWorkerId)
      ) {
        return options;
      }

      options.push({
        value: id,
        label: `${name} (${getHumanReadableTimeTitle(length)})`,
      });
      return options;
    }, [])
    .sort((valueA, valueB) => valueA.label.localeCompare(valueB.label));

  return (
    <ModalLayout
      title={initialData?.serviceId ? "Atnaujinti vizitą" : "Pridėti vizitą"}
      disabled={disabled}
      onClose={onClose}
      formId={formId}
      onRemoveClick={
        onRemove
          ? () => {
              const root = createRoot(
                document.getElementById("modal-confirm")!,
              );
              root.render(
                <ModalLayout
                  autoFocusCancel
                  title="Ištrinti vizitą"
                  saveTitle="Ištrinti"
                  saveClassName="btn-danger"
                  cancelTitle="Atšaukti"
                  onClose={() => root.unmount()}
                  onSave={() => {
                    root.unmount();
                    onRemove?.(
                      () => setDisabled(true),
                      (message) => {
                        createErrorModal({ message });
                        setDisabled(false);
                      },
                    );
                  }}
                >
                  Ar tikrai norite pašalinti vizitą?
                </ModalLayout>,
              );
            }
          : undefined
      }
    >
      <form
        id={formId}
        onSubmit={(event) => {
          event.preventDefault();

          if (!selectedPrimaryServiceId || !selectedWorkerId) {
            return;
          }

          if (!selectedDate) {
            createErrorModal({
              message: "Pradžios data nebuvo pasirinkta.",
            });
            return;
          }

          if (!primaryService) {
            createErrorModal({
              message: "Nepavyko rasti pasirinktos pagrindinės paslaugos.",
            });
            return;
          }

          if (!primaryVisitEnd) {
            createErrorModal({
              message: "Nepavyko nustatyti vizito pabaigos.",
            });
            return;
          }

          const formData = new FormData(event.currentTarget);
          const name = nameInputRef.current?.getInput()?.value || "";
          const phone = phoneInputRef.current?.getInput()?.value || "";

          if (name.length > 100 || phone.length > 12 || name.length === 0) {
            createErrorModal({
              message: (
                <>
                  <p>Netinkamai užpildyta forma:</p>
                  <ul>
                    {name.length === 0 ? (
                      <li>Vardas negali būti tuščias.</li>
                    ) : null}
                    {name.length > 100 ? (
                      <li>Vardas negali būti ilgesnis už šimtą simbolių.</li>
                    ) : null}
                    {phone.length > 12 ? (
                      <li>Telefonas negali būti ilgesnis už 12 simbolių.</li>
                    ) : null}
                  </ul>
                </>
              ),
            });
            return;
          }

          const primaryVisit = {
            id: initialData?.id || "",
            start: selectedDate,
            end:
              isEdit && !hasReservationEndChanged
                ? // In edit we'll definitelly have the end date.
                  initialData.end!
                : primaryVisitEnd,
            workerId: selectedWorkerId,
            serviceId: selectedPrimaryServiceId,
            title: resolveVisitTitle(name, primaryService?.name),
            client: {
              name,
              phone,
            },
            skipNotification: formData.get("remindViaSms") !== "on",
          };

          onSave(
            primaryVisit,
            secondaryService
              ? {
                  ...primaryVisit,
                  serviceId: secondaryService.id,
                  title: resolveVisitTitle(name, secondaryService?.name),
                  start: primaryVisitEnd,
                  end: addMinutes(
                    primaryVisitEnd,
                    getMinutesFromLength(secondaryService.length),
                  ),
                }
              : undefined,
            () => setDisabled(true),
            (message) => {
              createErrorModal({ message });
              setDisabled(false);
            },
          );
        }}
      >
        <div className="mb-3">
          <label htmlFor="service" className="form-label">
            Paslauga
          </label>
          <SelectComponent
            id="service"
            autoFocus
            placeholder="Pasirinkite paslaugą..."
            onKeyDown={(event) => event.stopPropagation()}
            options={primaryServiceOptions}
            value={
              primaryServiceOptions.find(
                ({ value }) => value === selectedPrimaryServiceId,
              ) || null
            }
            onChange={(option) => {
              const newPrimaryServiceId = option?.value;
              const newPrimaryService = newPrimaryServiceId
                ? serviceList?.find(({ id }) => id === newPrimaryServiceId)
                : undefined;

              const doesSelectedWorkerPerformNewService =
                newPrimaryService?.workerIdList.some(
                  ({ workerId }) => workerId === selectedWorkerId,
                );

              if (!doesSelectedWorkerPerformNewService) {
                setSelectedWorkerId(undefined);
                setShowSecondaryService(false);
                setSelectedSecondaryServiceId(undefined);
              } else if (newPrimaryServiceId === selectedSecondaryServiceId) {
                setSelectedSecondaryServiceId(undefined);
              }

              setSelectedPrimaryServiceId(option?.value);
              setHasReservationEndChanged(true);
            }}
            noOptionsMessage={() => "Nėra pasirinkimo"}
            styles={{
              menu: (provided) => {
                return { ...provided, zIndex: 15 };
              },
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="employee" className="form-label">
            Darbuotojas
          </label>
          <SelectComponent
            id="employee"
            placeholder="Pasirinkite darbuotoją..."
            onKeyDown={(event) => event.stopPropagation()}
            options={workerListOptions}
            value={
              workerListOptions?.find(
                ({ value }) => value === selectedWorkerId,
              ) || null
            }
            onChange={(option) => {
              const newWorkerId = option?.value;
              setSelectedWorkerId(option?.value);
              setHasReservationEndChanged(true);

              const doesNewWorkerPerformAdditionalService =
                secondaryService?.workerIdList.some(
                  ({ workerId }) => workerId === newWorkerId,
                );

              if (!doesNewWorkerPerformAdditionalService) {
                setSelectedSecondaryServiceId(undefined);
              }
            }}
          />
          {!isEdit && selectedWorkerId ? (
            <button
              type="button"
              className={classNames(
                "link-primary",
                s.addAdditionalServiceButton,
              )}
              onClick={() => {
                setShowSecondaryService(!showSecondaryService);
                if (showSecondaryService) {
                  setSelectedSecondaryServiceId(undefined);
                  return;
                }

                setTimeout(() => {
                  secondaryServiceSelectRef.current?.focus();
                }, 100);
              }}
            >
              {showSecondaryService
                ? "- Atšaukti papilomą paslaugą"
                : "+ Pridėti papildomą paslaugą"}
            </button>
          ) : null}
          {showSecondaryService ? (
            <SelectComponent
              isClearable
              className={s.secondaryServiceSelect}
              placeholder="Pasirinkite papildomą paslaugą..."
              onKeyDown={(event) => event.stopPropagation()}
              ref={secondaryServiceSelectRef}
              options={secondaryServiceOptions}
              value={
                secondaryServiceOptions?.find(
                  ({ value }) => value === selectedSecondaryServiceId,
                ) || null
              }
              onChange={(value) => {
                setSelectedSecondaryServiceId(value?.value);
              }}
            />
          ) : null}
        </div>
        <div className="mb-3">
          <label htmlFor="date" className="form-label">
            Pradžia
          </label>
          <div className={s.startDatePickerContainer}>
            {availabilityResponse.status === Status.Pending ? (
              <Loader className={s.loader} />
            ) : availabilityResponse.status === Status.Loaded ? (
              availabilityResponse.value ? (
                <DatePicker
                  selected={selectedDate}
                  onChange={(date: Date) => {
                    setHasReservationEndChanged(true);
                    const targetDay = getTargetDay(date);
                    const daysAvailabilities =
                      availabilityResponse.value?.availableTimesByDate[
                        targetDay
                      ];
                    const newDate = daysAvailabilities?.find(
                      (ongoingDate) =>
                        ongoingDate.toISOString() ===
                        new Date(date).toISOString(),
                    );

                    setSelectedDate(
                      newDate ? new Date(newDate) : daysAvailabilities?.[0],
                    );
                  }}
                  includeDates={includeDates}
                  includeTimes={includeTimes}
                />
              ) : (
                <div>Pasirinkite paslaugą bei darbuotoją</div>
              )
            ) : (
              <div>
                Nepavyko užkrauti galimų pradžios laikų.{" "}
                <span
                  className={s.link}
                  onClick={() =>
                    setAvailabilityFetchId(availabilityFetchId + 1)
                  }
                >
                  Pabandykim dar kartą?
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="end-time" className="form-label">
            Pabaiga
          </label>
          <input
            className="form-control"
            readOnly={true}
            value={
              reservationEnd ? format(reservationEnd, "yyyy-MM-dd HH:mm") : ""
            }
          />
        </div>
        <div className="mb-3">
          <label htmlFor="clientName" className="form-label">
            Vardas
          </label>
          <AsyncTypeahead
            id="clientName"
            onKeyDown={(event) => {
              // Stop conflicting with the modal keyboard events handling...
              event.stopPropagation();
            }}
            disabled={disabled}
            isLoading={isNameSearchPending}
            filterBy={() => true}
            onSearch={async (query: string) => {
              try {
                setIsNameSearchPending(true);
                const clients = await Client.searchName(query);
                setClientsByName(clients);
              } catch (error) {
                console.error(error);
              } finally {
                setIsNameSearchPending(false);
              }
            }}
            onChange={(selected) => {
              const selectedOptions = selected as Client[] | undefined;
              const { normalizedPhone } = selectedOptions?.[0] || {};
              setSelectedName(selected);
              setSelectedPhone(
                normalizedPhone ? [normalizedPhone] : selectedPhone,
              );
            }}
            options={clientByName}
            labelKey="name"
            minLength={3}
            placeholder="Ieškoti pagal vardą..."
            emptyLabel="Nėra pasirinkimų..."
            searchText="Ieškoma..."
            promptText="Praplėskite paieškos frazę..."
            selected={selectedName}
            ref={nameInputRef}
            renderMenuItemChildren={(option, { text }) => {
              if (typeof option === "string") {
                return <Highlighter search={text}>{option}</Highlighter>;
              }

              return (
                <>
                  <Highlighter search={text}>{option.name}</Highlighter>
                  {option.normalizedPhone ? (
                    <div>
                      <small>Tel. nr.: {option.normalizedPhone}</small>
                    </div>
                  ) : null}
                </>
              );
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="clientPhone" className="form-label">
            Telefono nr.
          </label>
          <AsyncTypeahead
            id="clientPhone"
            onKeyDown={(event) => {
              // Stop conflicting with the modal keyboard events handling...
              event.stopPropagation();
            }}
            disabled={disabled}
            isLoading={isPhoneSearchPending}
            filterBy={() => true}
            onSearch={async (query: string) => {
              try {
                setIsPhoneSearchPending(true);
                const clients = await Client.searchPhone(query);
                setClientsByPhone(clients);
              } catch (error) {
                console.error(error);
              } finally {
                setIsPhoneSearchPending(false);
              }
            }}
            onChange={(selected) => {
              const selectedOptions = selected as Client[] | undefined;
              const { name } = selectedOptions?.[0] || {};
              setSelectedName(name ? [name] : selectedName);
              setSelectedPhone(selected);
            }}
            options={clientByPhone}
            labelKey="normalizedPhone"
            minLength={2}
            placeholder="Ieškoti pagal telefono numerį..."
            emptyLabel="Nėra pasirinkimų..."
            searchText="Ieškoma..."
            promptText="Praplėskite paieškos frazę..."
            selected={selectedPhone}
            ref={phoneInputRef}
            renderMenuItemChildren={(option, { text }) => {
              if (typeof option === "string") {
                return <Highlighter search={text}>{option}</Highlighter>;
              }

              return (
                <>
                  <Highlighter search={text}>
                    {option.normalizedPhone}
                  </Highlighter>
                  {option.name ? (
                    <div>
                      <small>Vardas: {option.name}</small>
                    </div>
                  ) : null}
                </>
              );
            }}
          />
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="remindViaSms"
            name="remindViaSms"
            disabled={disabled}
            defaultChecked={
              initialData?.skipNotification == null
                ? true
                : !initialData?.skipNotification
            }
          />
          <label className="form-check-label" htmlFor="remindViaSms">
            Priminti apie apsilankymą SMS
          </label>
        </div>
      </form>
    </ModalLayout>
  );
};
