import { useState } from "react";
import format from "date-fns/format";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { createRoot } from "react-dom/client";
import { ModalLayout } from "../modal/ModalLayout";
import { Employee } from "../../api/Organization";
import { WorkingTimeSlot } from "../../api/WorkingTimeSlot";
import { TimePicker } from "../time-picker";
import { MONTH_TITLE_MAP, WEEKDAY_TITLE } from "../../helpers/date-helpers";

interface Props {
    initialData?: Partial<WorkingTimeSlot>;
    employee: Employee;
    onClose: () => void;
    onSave: (
        visit: WorkingTimeSlot,
        setDisabled: (disabled: boolean) => void,
    ) => void;
    min: Date;
    max: Date;
    onRemove?: (setDisabled: (disabled: boolean) => void) => void;
}

export const WorkingTimeSlotModal = ({
    initialData,
    employee,
    min,
    max,
    onClose,
    onSave,
    onRemove,
}: Props) => {
    const formId = "working-time-slot-form";
    const [disabled, setDisabled] = useState(false);
    const [start, setStart] = useState(
        initialData?.start ? format(initialData.start, "HH:mm") : "",
    );
    const [startHours, startMinutes] = start
        .split(":")
        .map((value) => (!value ? 0 : Number(value)));
    const [end, setEnd] = useState(
        initialData?.end ? format(initialData.end, "HH:mm") : "",
    );
    // Add 15 minutes to the start.
    const minEnd = format(
        setHours(setMinutes(new Date(), startMinutes + 15), startHours),
        "HH:mm",
    );
    const [endHours, endMinutes] = end
        .split(":")
        .map((value) => (!value ? 0 : Number(value)));
    const maxStart = format(
        setHours(setMinutes(new Date(), endMinutes - 15), endHours),
        "HH:mm",
    );
    const isEdit = !!onRemove;

    return (
        <ModalLayout
            title={
                !isEdit
                    ? "Pridėti darbo laiko įrašą"
                    : "Keisti darbo laiko įrašą"
            }
            onClose={onClose}
            disabled={disabled}
            formId={formId}
            onRemoveClick={
                onRemove
                    ? () => {
                          const root = createRoot(
                              document.getElementById("modal-confirm")!,
                          );
                          root.render(
                              <ModalLayout
                                  autoFocusCancel
                                  title="Ištrinti darbo laiko įrašą"
                                  saveTitle="Ištrinti"
                                  saveClassName="btn-danger"
                                  cancelTitle="Atšaukti"
                                  onClose={() => root.unmount()}
                                  onSave={() => {
                                      root.unmount();
                                      onRemove?.(setDisabled);
                                  }}
                              >
                                  Ar tikrai norite pašalinti darbo laiko įrašą?
                              </ModalLayout>,
                          );
                      }
                    : undefined
            }
        >
            <form
                id={formId}
                onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    onSave(
                        {
                            id: initialData?.id || "",
                            start: new Date(
                                `${formData.get("date")} ${formData.get(
                                    "start",
                                )}`,
                            ),
                            end: new Date(
                                `${formData.get("date")} ${formData.get(
                                    "end",
                                )}`,
                            ),
                            workerId: employee.id,
                        },
                        setDisabled,
                    );
                }}
            >
                <div className="mb-3">
                    <label htmlFor="employee" className="form-label">
                        Darbuotojas
                    </label>
                    <input
                        readOnly
                        type="text"
                        className="form-control"
                        id="employee"
                        defaultValue={employee.displayName}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                        Diena
                    </label>
                    <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="date"
                        value={
                            initialData?.start
                                ? `${format(
                                      initialData.start,
                                      "yyyy-MM-dd",
                                  )} (${
                                      MONTH_TITLE_MAP[
                                          initialData.start.getMonth()
                                      ]
                                  } ${initialData.start.getDate()} d., ${
                                      WEEKDAY_TITLE[initialData.start.getDay()]
                                  })`
                                : ""
                        }
                    />
                    <input
                        type="hidden"
                        name="date"
                        defaultValue={
                            initialData?.start
                                ? format(initialData.start, "yyyy-MM-dd")
                                : ""
                        }
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="start-time" className="form-label">
                        Pradžia
                    </label>
                    <TimePicker
                        autoFocus={isEdit}
                        name="start"
                        className="form-control"
                        id="start-time"
                        required
                        value={start}
                        onChange={(event) => {
                            setStart(event.target.value);
                        }}
                        min={format(min, "HH:mm")}
                        max={maxStart}
                        step={15}
                        disabled={disabled}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="end-time" className="form-label">
                        Pabaiga
                    </label>
                    <TimePicker
                        autoFocus={!isEdit}
                        name="end"
                        className="form-control"
                        id="end-time"
                        required
                        min={minEnd}
                        max={format(max, "HH:mm")}
                        value={end}
                        onChange={(event) => {
                            setEnd(event.target.value);
                        }}
                        step={15}
                        disabled={disabled}
                    />
                </div>
            </form>
        </ModalLayout>
    );
};
