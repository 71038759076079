import classNames from "classnames";
import { ReactNode, useEffect, useMemo, useRef } from "react";

interface Props {
    title: string;
    children: ReactNode;
    formId?: string;
    saveTitle?: string;
    cancelTitle?: string;
    saveClassName?: string;
    contentClassName?: string;
    disabled?: boolean;
    autoFocusCancel?: boolean;
    onClose: () => void;
    onSave?: () => void;
    onRemoveClick?: () => void;
}

export const ModalLayout = ({
    title,
    children,
    formId,
    saveTitle = "Išsaugoti",
    cancelTitle = "Uždaryti",
    saveClassName,
    contentClassName,
    disabled,
    autoFocusCancel = false,
    onClose,
    onSave,
    onRemoveClick,
}: Props) => {
    const lastActiveElement = useMemo(() => {
        return document.activeElement as HTMLElement;
    }, []);
    const onModalClose = () => {
        onClose();
        lastActiveElement?.focus();
    };
    const containerRef = useRef<HTMLDivElement>(null);
    const submitButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const keyboardEventHandler = (event: KeyboardEvent) => {
            if (
                (containerRef.current?.parentElement?.id === "modal" &&
                    document.getElementById("modal-confirm")?.firstChild !=
                        null) ||
                disabled
            ) {
                return;
            }

            switch (event.key) {
                case "Escape": {
                    onModalClose();
                    return;
                }
            }
        };
        document.addEventListener("keydown", keyboardEventHandler);

        return () => {
            document.removeEventListener("keydown", keyboardEventHandler);
        };
    }, []);

    return (
        <div
            className="modal fade show"
            id="exampleModalScrollable"
            tabIndex={-1}
            aria-labelledby="exampleModalScrollableTitle"
            aria-modal="true"
            role="dialog"
            style={{ display: "block", background: "#00000099" }}
            ref={containerRef}
        >
            <div className="modal-dialog modal-dialog-scrollable modal-fullscreen-lg-down modal-lg">
                <div className={classNames("modal-content", contentClassName)}>
                    <div className="modal-header">
                        <h5
                            className="modal-title"
                            id="exampleModalScrollableTitle"
                        >
                            {title}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onModalClose}
                            disabled={disabled}
                        ></button>
                    </div>
                    <div className="modal-body">{children}</div>
                    <div className="modal-footer">
                        {onRemoveClick ? (
                            <button
                                type="button"
                                className="btn me-auto btn-danger"
                                onClick={onRemoveClick}
                                disabled={disabled}
                            >
                                Ištrinti
                            </button>
                        ) : null}

                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={onModalClose}
                            disabled={disabled}
                            autoFocus={autoFocusCancel}
                        >
                            {cancelTitle}
                        </button>
                        {!formId && !onSave ? null : (
                            <button
                                type={formId ? "submit" : "button"}
                                className={classNames(
                                    "btn",
                                    saveClassName || "btn-primary",
                                )}
                                onClick={onSave}
                                form={formId}
                                ref={submitButtonRef}
                                disabled={disabled}
                            >
                                {saveTitle}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
