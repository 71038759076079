import ReactDOM from "react-dom/client";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import {
    IMsalContext,
    MsalAuthenticationResult,
    MsalAuthenticationTemplate,
    MsalProvider,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import "./index.scss";

import { UserProvider } from "./components/user-provider";
import { ShowByUserType } from "./components/show-by-user-type/show-by-user-type";
import { WeekSchedulePage } from "./pages/WeekSchedulePage";
import { WorkingTimePage } from "./pages/WorkingTimePage";
import { EmployeesPage } from "./pages/EmployeesPage";
import { ServicesPage } from "./pages/ServicesPage";
import { MyProfilePage } from "./pages/MyProfilePage";
import { ServiceCategoriesPage } from "./pages/ServiceCategoriesPage";
import { LogoutPage } from "./pages/LogoutPage";
import { HomePage } from "./pages/HomePage";
import { MSAL_INSTANCE } from "./api/api-config";
import { AuthProvider } from "./components/auth-provider";

function ErrorComponent({ error }: MsalAuthenticationResult) {
    return <p>An Error Occurred: {error?.errorMessage}</p>;
}

function LoadingComponent(_props: IMsalContext) {
    return <p>Authentication in progress...</p>;
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const AuthElement = ({ children }: { children: JSX.Element }) => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{
                scopes: ["openid", "profile", "email"],
            }}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            {children}
        </MsalAuthenticationTemplate>
    );
};

root.render(
    <BrowserRouter>
        <MsalProvider instance={MSAL_INSTANCE}>
            <AuthProvider>
                <UserProvider>
                    <Routes>
                        <Route
                            path="/schedule-week"
                            element={
                                <AuthElement>
                                    <WeekSchedulePage />
                                </AuthElement>
                            }
                        />
                        <Route
                            path="/working-time"
                            element={
                                <AuthElement>
                                    <WorkingTimePage />
                                </AuthElement>
                            }
                        />
                        <Route
                            path="/workers"
                            element={
                                <AuthElement>
                                    <ShowByUserType>
                                        <EmployeesPage />
                                    </ShowByUserType>
                                </AuthElement>
                            }
                        />
                        <Route
                            path="/services"
                            element={
                                <AuthElement>
                                    <ShowByUserType>
                                        <ServicesPage />
                                    </ShowByUserType>
                                </AuthElement>
                            }
                        />
                        {/* <Route
                            path="/settings"
                            element={
                                <AuthElement>
                                    <ShowByUserType>
                                        <SettingsPage />
                                    </ShowByUserType>
                                </AuthElement>
                            }
                        /> */}
                        <Route
                            path="/profile"
                            element={
                                <AuthElement>
                                    <MyProfilePage />
                                </AuthElement>
                            }
                        />
                        <Route
                            path="/service-categories"
                            element={
                                <AuthElement>
                                    <ShowByUserType>
                                        <ServiceCategoriesPage />
                                    </ShowByUserType>
                                </AuthElement>
                            }
                        />
                        <Route
                            path="/logout"
                            element={
                                <AuthElement>
                                    <LogoutPage />
                                </AuthElement>
                            }
                        />
                        <Route
                            path="/callback"
                            element={
                                <AuthElement>
                                    <Navigate to="/schedule-week" replace />
                                </AuthElement>
                            }
                        />
                        <Route path="/" element={<HomePage />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </UserProvider>
            </AuthProvider>
        </MsalProvider>
    </BrowserRouter>,
);
