import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";

interface Props
    extends React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    min?: string;
    max?: string;
    /**
     * Minutes
     * @default 1
     */
    step: number;
}

export const TimePicker = ({
    min = "00:00",
    max = "23:59",
    step = 1,
    ...restProps
}: Props) => {
    const [minHours, minMinutes] = (min || "00:00").split(":");
    const minDate = min
        ? setMinutes(setHours(new Date(), Number(minHours)), Number(minMinutes))
        : startOfDay(new Date());
    const [maxHours, maxMinutes] = (max || "00:00").split(":");
    const maxDate = max
        ? setMinutes(setHours(new Date(), Number(maxHours)), Number(maxMinutes))
        : endOfDay(new Date());
    const options: JSX.Element[] = [];

    for (
        let ongoingDate = new Date(minDate);
        ongoingDate <= maxDate;
        ongoingDate = addMinutes(ongoingDate, step)
    ) {
        const value = format(ongoingDate, "HH:mm");
        options.push(
            <option key={value} value={value}>
                {value}
            </option>,
        );
    }
    return <select {...restProps}>{options}</select>;
};
