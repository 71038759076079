import { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { ModalLayout } from "../components/modal/ModalLayout";

export interface Params {
    message: ReactNode;
}

export const createErrorModal = ({ message }: Params) => {
    const root = createRoot(document.getElementById("modal-confirm")!);
    root.render(
        <ModalLayout
            autoFocusCancel
            title="Įvyko klaida"
            cancelTitle="Supratau"
            onClose={() => root.unmount()}
        >
            {message}
        </ModalLayout>,
    );
};
