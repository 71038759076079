import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";

export interface WorkerTime {
    id: string;
    workerId: string;
    organizationId: string;
    date: string;
    start: string;
    end: string;
}

export type WorkerTimeUpdateDto = Omit<WorkerTime, "organizationId">;

export interface WorkerTimeCopyDto {
    workerId: string;
    toDate: string;
}

interface Options {
    signal?: AbortSignal;
}

const BASE_URL = `${API_BASE_URL}/api/WorkerTime`;

export const WorkerTimeApi = {
    async search(
        workerId: string | undefined,
        start: string,
        end: string,
        requestOptions: Options,
    ): Promise<WorkerTime[]> {
        const result = await fetch(`${BASE_URL}/search`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify({
                workerId,
                start,
                end,
            }),
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }

        return result.json();
    },
    async deleteWorkerTime(workerTimeId: string) {
        const result = await fetch(`${BASE_URL}/${workerTimeId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async create(body: WorkerTimeUpdateDto) {
        const result = await fetch(BASE_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify(body),
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async update(
        workerTimeId: string,
        body: {
            date: string;
            start: string;
            end: string;
        },
    ) {
        const result = await fetch(`${BASE_URL}/${workerTimeId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify(body),
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async copy(params: WorkerTimeCopyDto): Promise<WorkerTime[]> {
        const result = await fetch(`${BASE_URL}/copy`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify(params),
        });

        if (!result.ok) {
            throw await result.json();
        }

        return result.json();
    },
};
