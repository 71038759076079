import { useState } from "react";
import { createRoot } from "react-dom/client";
import { Employee, UserType } from "../../api/Organization";
import { createErrorModal } from "../../helpers/error-helpers";
import { ModalLayout } from "../modal/ModalLayout";

interface Props {
  onClose: () => void;
  onRemove?: (
    setDisabled: () => void,
    setError: (message: string) => void,
  ) => void;
  onSave: (
    employee: Employee,
    setError: (error: string) => void,
    setDisabled: () => void,
  ) => void;
  initialData?: Partial<Employee>;
}

export const EmployeeModal = ({
  initialData = {},
  onSave,
  onClose,
  onRemove,
}: Props) => {
  const [disabled, setDisabled] = useState(false);
  const formId = "employee-form";
  return (
    <ModalLayout
      title={
        initialData.id
          ? "Redaguoti darbuotojo įrašą"
          : "Sukurti darbuotojo įrašą"
      }
      onClose={onClose}
      formId={formId}
      disabled={disabled}
      onRemoveClick={
        onRemove
          ? () => {
              const root = createRoot(
                document.getElementById("modal-confirm")!,
              );
              root.render(
                <ModalLayout
                  autoFocusCancel
                  title="Ištrinti darbuotoją"
                  saveTitle="Ištrinti"
                  saveClassName="btn-danger"
                  cancelTitle="Atšaukti"
                  onClose={() => root.unmount()}
                  onSave={() => {
                    root.unmount();
                    onRemove?.(
                      () => setDisabled(true),
                      (message) => {
                        createErrorModal({ message });
                        setDisabled(false);
                      },
                    );
                  }}
                >
                  Ar tikrai norite pašalinti darbuotoją?
                </ModalLayout>,
              );
            }
          : undefined
      }
    >
      <form
        id={formId}
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          onSave(
            {
              id: initialData?.id || "",
              organizationId: initialData.organizationId || "",
              description: formData.get("description") as string,
              displayName: formData.get("displayName") as string,
              userType: initialData.userType || UserType.Worker,
              email: (formData.get("email") as string) || null,
            },
            (error) => {
              createErrorModal({ message: error });
              setDisabled(false);
            },
            () => setDisabled(true),
          );
        }}
      >
        <div className="mb-3">
          <label htmlFor="displayName" className="form-label">
            Pavadinimas
          </label>
          <input
            autoFocus
            type="text"
            name="displayName"
            className="form-control"
            id="displayName"
            required
            minLength={3}
            maxLength={100}
            defaultValue={initialData.displayName}
            disabled={disabled}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Aprašymas
          </label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            defaultValue={initialData.description || ""}
            disabled={disabled}
            minLength={3}
            maxLength={300}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            El. paštas
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            id="email"
            maxLength={100}
            defaultValue={initialData.email || ""}
            disabled={disabled}
          />
        </div>
      </form>
    </ModalLayout>
  );
};
