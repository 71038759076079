export const joinTime = (minutes: number, hours?: number) => {
    if (!hours) {
        return `${minutes} min.`;
    }

    if (!minutes) {
        return `${hours} val.`;
    }

    return `${hours} val. ${minutes} min.`;
};

export function getHumanReadableTimeTitle(time: string): string;
export function getHumanReadableTimeTitle(minutes: number): string;

export function getHumanReadableTimeTitle(value: string | number): string {
    if (typeof value == "string") {
        const [hoursString, minutesString, _secondsString] = value.split(":");
        const hours = Number(hoursString);
        const minutes = Number(minutesString);

        return joinTime(minutes, hours);
    }

    const minutes = value % 60;
    const hours = Math.floor(value / 60);

    return joinTime(minutes, hours);
}

export const getMinutesFromLength = (time: string) => {
    const [hoursString, minutesString, _secondsString] = time.split(":");
    const hours = Number(hoursString);
    const minutes = Number(minutesString);

    return hours * 60 + minutes;
};

export const getLengthFromMinutes = (minutes: number) => {
    const minutesLeft = Math.floor(minutes % 60);
    const hours = Math.floor(minutes / 60);
    return `${hours.toString().padStart(2, "0")}:${minutesLeft.toString().padStart(2, "0")}:00`;
};
