import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";

interface TimeRange {
  start: string;
  end: string;
}

export interface Employee {
  id: string;
  displayName: string;
  description: string | null;
  organizationId: string;
  userType: UserType;
  email: string | null;
}

export interface ServiceEmployee {
  workerId: string;
  price: number;
}

export interface Service {
  id: string;
  name: string;
  categoryId: string;
  length: string;
  workerIdList: ServiceEmployee[];
  order: number;
}

export interface Category {
  id: string;
  name: string;
  order: number;
}

export interface OrganizationInfo {
  id: string;
  name: string;
  workTime: Record<string, TimeRange | null>;
  workerList: Employee[];
  serviceList: Service[];
  categoryList: Category[];
}

export enum UserType {
  SuperAdmin = 1,
  Manager,
  ManagerWorker,
  Worker,
}

export interface UserInfo {
  userDisplayName: string;
  objectId: string;
  userType: UserType;
  organizationId: string;
  workerId: string;
}

const BASE_URL = `${API_BASE_URL}/api/Organization`;

interface Options {
  signal?: AbortSignal;
}

export const Organization = {
  OrganizationInfo: async (
    requestOptions: Options,
  ): Promise<OrganizationInfo> => {
    return (
      await fetch(`${BASE_URL}/OrganizationInfo/${ORGANIZATION_ID}`, {
        method: "GET",
        headers: {
          "X-OrganizationId": ORGANIZATION_ID,
          Authorization: await getAuthorizationHeader(),
        },
        ...requestOptions,
      })
    ).json();
  },
  InsideOrganizationInfo: async (
    requestOptions: Options,
  ): Promise<OrganizationInfo> => {
    return (
      await fetch(`${BASE_URL}/InsideOrganizationInfo`, {
        method: "GET",
        headers: {
          "X-OrganizationId": ORGANIZATION_ID,
          Authorization: await getAuthorizationHeader(),
        },
        ...requestOptions,
      })
    ).json();
  },
  UserInfo: async (requestOptions: Options): Promise<UserInfo> => {
    return (
      await fetch(`${BASE_URL}/UserInfo`, {
        method: "GET",
        headers: {
          "X-OrganizationId": ORGANIZATION_ID,
          Authorization: await getAuthorizationHeader(),
        },
        ...requestOptions,
      })
    ).json();
  },
};
