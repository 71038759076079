import { Reducer, useEffect, useReducer } from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuid } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Status } from "../api/ApiContracts";
import { Category, Employee, Organization, Service } from "../api/Organization";
import { ServiceApi } from "../api/Service";
import { ErrorSection } from "../components/error-section/ErrorSection";
import { Loader } from "../components/loader";
import { MainLayout } from "../components/main-layout/MainLayout";
import { ServiceModal } from "../components/service-modal/ServiceModal";
import { ModalLayout } from "../components/modal/ModalLayout";
import { getPriceTitle } from "../helpers/cost-helpers";
import { getHumanReadableTimeTitle } from "../helpers/time-helpers";
import { useIsMounted } from "../hooks/useIsMounted";
import { createErrorModal } from "../helpers/error-helpers";
import s from "./ServicesPage.module.scss";

enum EditMode {
  Crud = "crud",
  Rearange = "rearange",
}

interface State {
  services: Service[];
  categories: Category[];
  employees: Employee[];
  servicesStatus: Status;
  servicesFetchId: number;
  organizationId?: string;
  editMode: EditMode;
  serviceIdsByCategoryId: Record<string, string[] | undefined>;
  orderSaveInProgress: boolean;
}

enum ActionType {
  AddService = "add-service",
  UpdateService = "update-service",
  RemoveService = "remove-service",
  SetServicesStatus = "set-status",
  RetryServicesLoad = "retry-services-load",
  EditMode = "edit-mode",
  CategoryMove = "category-move",
  ServiceMove = "service-move",
  OrderSavingProgressChange = "order-saving-progress-change",
}

interface AddServiceAction {
  type: ActionType.AddService;
  service: Service;
}

interface UpdateServiceAction {
  type: ActionType.UpdateService;
  service: Service;
}

interface SetServicesStatusAction {
  type: ActionType.SetServicesStatus;
  status: Status;
  services?: Service[];
  employees?: Employee[];
  categories?: Category[];
  organizationId?: string;
  serviceIdsByCategoryId?: Record<string, string[] | undefined>;
}

interface RetryServicesLoadAction {
  type: ActionType.RetryServicesLoad;
}

interface RemoveServiceAction {
  type: ActionType.RemoveService;
  id: string;
}

interface ChangeEditModeAction {
  type: ActionType.EditMode;
  newEditMode: EditMode;
}

enum CategoryMoveDirection {
  Up,
  Down,
}

interface CategoryMoveAction {
  type: ActionType.CategoryMove;
  direction: CategoryMoveDirection;
  categoryId: string;
}

interface ServiceMoveAction {
  type: ActionType.ServiceMove;
  categoryId: string;
  serviceId: string;
  destinationIndex: number;
  sourceIndex: number;
}

interface OrderSavingProgressChangeAction {
  type: ActionType.OrderSavingProgressChange;
  inProgress: boolean;
}

type Action =
  | AddServiceAction
  | UpdateServiceAction
  | SetServicesStatusAction
  | RetryServicesLoadAction
  | RemoveServiceAction
  | ChangeEditModeAction
  | CategoryMoveAction
  | ServiceMoveAction
  | OrderSavingProgressChangeAction;

export const ServicesPage = () => {
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    (state, action) => {
      switch (action.type) {
        case ActionType.AddService: {
          return {
            ...state,
            services: [...state.services, action.service],
            serviceIdsByCategoryId: {
              ...state.serviceIdsByCategoryId,
              [action.service.categoryId]: [
                ...(state.serviceIdsByCategoryId[action.service.categoryId] ||
                  []),
                action.service.id,
              ],
            },
          };
        }
        case ActionType.UpdateService: {
          return {
            ...state,
            services: state.services.reduce<Service[]>(
              (newService, service) => {
                if (service.id === action.service.id) {
                  newService.push(action.service);
                } else {
                  newService.push(service);
                }

                return newService;
              },
              [],
            ),
          };
        }
        case ActionType.SetServicesStatus: {
          return {
            ...state,
            employees: action.employees || [],
            services: action.services || [],
            categories: action.categories || [],
            serviceIdsByCategoryId: action.serviceIdsByCategoryId || {},
            servicesStatus: action.status,
            organizationId: action.organizationId,
          };
        }
        case ActionType.RetryServicesLoad: {
          return {
            ...state,
            services: [],
            servicesStatus: Status.Pending,
            servicesFetchId: state.servicesFetchId + 1,
          };
        }
        case ActionType.RemoveService: {
          const service = state.services.find(({ id }) => id !== action.id);

          if (!service) {
            return state;
          }

          return {
            ...state,
            services: state.services.filter(({ id }) => id !== action.id),
            serviceIdsByCategoryId: {
              ...state.serviceIdsByCategoryId,
              [service.categoryId]: (
                state.serviceIdsByCategoryId[service.categoryId] || []
              ).filter((id) => id !== action.id),
            },
          };
        }
        case ActionType.EditMode: {
          return {
            ...state,
            editMode: action.newEditMode,
          };
        }
        case ActionType.CategoryMove: {
          const categoryIndex = state.categories.findIndex(
            ({ id }) => action.categoryId === id,
          );

          if (
            categoryIndex == null ||
            (categoryIndex === 0 &&
              action.direction === CategoryMoveDirection.Up) ||
            (categoryIndex === state.categories.length - 1 &&
              action.direction === CategoryMoveDirection.Down)
          ) {
            return state;
          }

          const targetIndex =
            action.direction === CategoryMoveDirection.Up
              ? categoryIndex - 1
              : categoryIndex + 1;

          const category = state.categories[categoryIndex];
          const newCategories = [...state.categories];
          newCategories[categoryIndex] = newCategories[targetIndex];
          newCategories[targetIndex] = category;

          return {
            ...state,
            categories: newCategories,
          };
        }
        case ActionType.ServiceMove: {
          const moveItem = <TArray,>(
            array: TArray[],
            to: number,
            from: number,
          ) => {
            const item = array[from];
            array.splice(from, 1);
            array.splice(to, 0, item);
            return array;
          };

          const idsByCategory = [
            ...(state.serviceIdsByCategoryId[action.categoryId] || []),
          ];
          return {
            ...state,
            serviceIdsByCategoryId: {
              ...state.serviceIdsByCategoryId,
              [action.categoryId]: moveItem(
                idsByCategory,
                action.destinationIndex,
                action.sourceIndex,
              ),
            },
          };
        }
        case ActionType.OrderSavingProgressChange: {
          return {
            ...state,
            orderSaveInProgress: action.inProgress,
          };
        }
      }
    },
    {
      services: [],
      servicesStatus: Status.Pending,
      servicesFetchId: 0,
      categories: [],
      employees: [],
      editMode: EditMode.Crud,
      serviceIdsByCategoryId: {},
      orderSaveInProgress: false,
    },
  );

  useEffect(() => {
    const abortController = new AbortController();
    const loadData = async () => {
      try {
        const organizationInfo = await Organization.InsideOrganizationInfo({
          signal: abortController.signal,
        });
        if (!isMounted()) {
          return;
        }

        dispatch({
          type: ActionType.SetServicesStatus,
          status: Status.Loaded,
          services: organizationInfo.serviceList,
          employees: organizationInfo.workerList,
          categories: organizationInfo.categoryList,
          organizationId: organizationInfo.id,
          serviceIdsByCategoryId: organizationInfo.serviceList.reduce<
            Record<string, string[]>
          >((result, { id, categoryId }) => {
            if (!result[categoryId]) {
              result[categoryId] = [id];
            } else {
              result[categoryId].push(id);
            }

            return result;
          }, {}),
        });
      } catch (error) {
        if (
          error instanceof DOMException &&
          (error.name === "AbortError" || error.code === 20 || !isMounted())
        ) {
          return;
        }

        console.error(error);
        dispatch({
          type: ActionType.SetServicesStatus,
          status: Status.Error,
        });
      }
    };
    loadData();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.servicesFetchId]);

  const pageUnmountController = new AbortController();
  useEffect(() => {
    return () => pageUnmountController.abort();
  }, []);

  if (state.servicesStatus === Status.Pending) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (state.servicesStatus === Status.Error) {
    return (
      <MainLayout>
        <ErrorSection
          onRetryClick={() =>
            dispatch({
              type: ActionType.RetryServicesLoad,
            })
          }
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="container">
        <div className="d-flex flex-row align-items-center">
          <h1 className="py-4">Paslaugos</h1>
          {state.editMode === EditMode.Crud ? (
            <>
              <button
                type="button"
                className="btn btn-primary ms-4"
                onClick={() => {
                  const root = createRoot(document.getElementById("modal")!);

                  root.render(
                    <ServiceModal
                      categories={state.categories}
                      employees={state.employees}
                      onClose={() => root.unmount()}
                      onSave={async (updatedService, setDisabled, setError) => {
                        if (!state.organizationId) {
                          return;
                        }

                        try {
                          setDisabled();
                          const resultService = {
                            ...updatedService,
                            id: uuid(),
                          };
                          await ServiceApi.create(
                            {
                              id: resultService.id,
                              name: resultService.name,
                              organizationId: state.organizationId,
                              categoryId: resultService.categoryId,
                              length: resultService.length,
                              workerIdList: resultService.workerIdList,
                            },
                            {
                              signal: pageUnmountController.signal,
                            },
                          );

                          root.unmount();
                          dispatch({
                            type: ActionType.AddService,
                            service: resultService,
                          });
                        } catch (error) {
                          console.error(error);
                          if (pageUnmountController.signal.aborted) {
                            return;
                          }

                          setError(
                            "Nepavyko sukurti naujos paslaugos įrašo. Prašome bandyti vėliau.",
                          );
                        }
                      }}
                    />,
                  );
                }}
              >
                Sukurti įrašą
              </button>
              <button
                className="btn btn-primary ms-3"
                onClick={() => {
                  dispatch({
                    type: ActionType.EditMode,
                    newEditMode: EditMode.Rearange,
                  });
                }}
              >
                Keisti tvarką
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary ms-4"
                disabled={state.orderSaveInProgress}
                onClick={async () => {
                  try {
                    dispatch({
                      type: ActionType.OrderSavingProgressChange,
                      inProgress: true,
                    });

                    await ServiceApi.order({
                      Order: state.categories.map(({ id }) => ({
                        CategoryId: id,
                        ServiceIds: state.serviceIdsByCategoryId[id] || [],
                      })),
                    });
                    dispatch({
                      type: ActionType.EditMode,
                      newEditMode: EditMode.Crud,
                    });
                  } catch (error) {
                    console.error(error);
                    createErrorModal({
                      message:
                        "Nepavyko išsaugoti paslaugų tvarkos. Bandykite vėliau.",
                    });
                    dispatch({
                      type: ActionType.OrderSavingProgressChange,
                      inProgress: false,
                    });
                  }
                }}
              >
                Išsaugoti
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-3"
                disabled={state.orderSaveInProgress}
                onClick={() => {
                  const root = createRoot(
                    document.getElementById("modal-confirm")!,
                  );
                  root.render(
                    <ModalLayout
                      autoFocusCancel
                      title="Nutraukti tvarkos keitimą"
                      saveTitle="Taip"
                      cancelTitle="Nutraukti"
                      onClose={() => root.unmount()}
                      onSave={() => {
                        root.unmount();
                        dispatch({
                          type: ActionType.EditMode,
                          newEditMode: EditMode.Crud,
                        });
                        dispatch({
                          type: ActionType.RetryServicesLoad,
                        });
                      }}
                    >
                      Ar tikrai nutraukti tvarkos keitimą ir atšaukti
                      pakeitimus?
                    </ModalLayout>,
                  );
                }}
              >
                Atšaukti
              </button>
            </>
          )}
        </div>
        <div>
          <div>
            {state.categories.map(({ name, id }) => (
              <div className={s.categoryContainer} key={id}>
                <div className={s.categoryHeader}>
                  {state.editMode === EditMode.Crud ? null : (
                    <>
                      <button
                        className="d-flex btn btn-primary"
                        disabled={state.orderSaveInProgress}
                        onClick={() => {
                          dispatch({
                            type: ActionType.CategoryMove,
                            categoryId: id,
                            direction: CategoryMoveDirection.Up,
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-up-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"
                          />
                        </svg>
                      </button>
                      <button
                        className="d-flex btn btn-primary"
                        disabled={state.orderSaveInProgress}
                        onClick={() => {
                          dispatch({
                            type: ActionType.CategoryMove,
                            categoryId: id,
                            direction: CategoryMoveDirection.Down,
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-down-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                  <h2>{name}</h2>
                </div>
                <div className={s.tableContainer}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Pavadinimas</th>
                        <th scope="col">Trukmė</th>
                        <th scope="col">Kaina</th>
                        <th scope="col">Veiksmai</th>
                      </tr>
                    </thead>
                    <DragDropContext
                      onDragEnd={({ draggableId, destination, source }) => {
                        if (!destination) {
                          return;
                        }

                        dispatch({
                          type: ActionType.ServiceMove,
                          categoryId: destination.droppableId,
                          serviceId: draggableId,
                          destinationIndex: destination.index,
                          sourceIndex: source.index,
                        });
                      }}
                    >
                      <Droppable
                        droppableId={id}
                        isCombineEnabled={false}
                        isDropDisabled={state.editMode === EditMode.Crud}
                      >
                        {(droppableProvided) => {
                          return (
                            <tbody
                              ref={droppableProvided.innerRef}
                              {...droppableProvided.droppableProps}
                            >
                              {state.serviceIdsByCategoryId[id]?.map(
                                (serviceId, index) => {
                                  const service = state.services.find(
                                    ({ id }) => id === serviceId,
                                  );

                                  if (!service) {
                                    return null;
                                  }

                                  const { id, name, length, workerIdList } =
                                    service;

                                  return (
                                    <Draggable
                                      draggableId={service.id}
                                      index={index}
                                      key={service.id}
                                      isDragDisabled={
                                        state.editMode === EditMode.Crud ||
                                        state.orderSaveInProgress
                                      }
                                    >
                                      {(draggableProvided) => {
                                        return (
                                          <tr
                                            key={id}
                                            ref={draggableProvided.innerRef}
                                            className={s.serviceRow}
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                          >
                                            <td>{name}</td>
                                            <td>
                                              {getHumanReadableTimeTitle(
                                                length,
                                              )}
                                            </td>
                                            <td>
                                              {getPriceTitle(workerIdList)}
                                            </td>
                                            <td>
                                              {state.editMode ===
                                              EditMode.Crud ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={() => {
                                                    const root = createRoot(
                                                      document.getElementById(
                                                        "modal",
                                                      )!,
                                                    );

                                                    root.render(
                                                      <ServiceModal
                                                        initialData={service}
                                                        categories={
                                                          state.categories
                                                        }
                                                        employees={
                                                          state.employees
                                                        }
                                                        onClose={() =>
                                                          root.unmount()
                                                        }
                                                        onSave={async (
                                                          updatedService,
                                                          setDisabled,
                                                          setError,
                                                        ) => {
                                                          if (
                                                            !state.organizationId
                                                          ) {
                                                            return;
                                                          }

                                                          try {
                                                            setDisabled();
                                                            await ServiceApi.update(
                                                              updatedService.id,
                                                              {
                                                                id: updatedService.id,
                                                                name: updatedService.name,
                                                                organizationId:
                                                                  state.organizationId,
                                                                categoryId:
                                                                  updatedService.categoryId,
                                                                length:
                                                                  updatedService.length,
                                                                workerIdList:
                                                                  updatedService.workerIdList,
                                                              },
                                                              {
                                                                signal:
                                                                  pageUnmountController.signal,
                                                              },
                                                            );

                                                            root.unmount();
                                                            dispatch({
                                                              type: ActionType.UpdateService,
                                                              service:
                                                                updatedService,
                                                            });
                                                          } catch (error) {
                                                            console.error(
                                                              error,
                                                            );
                                                            if (
                                                              pageUnmountController
                                                                .signal.aborted
                                                            ) {
                                                              return;
                                                            }

                                                            setError(
                                                              "Nepavyko atnaujinti paslaugos įrašo. Prašome bandyti vėliau.",
                                                            );
                                                          }
                                                        }}
                                                        onRemove={async (
                                                          setDisabled,
                                                          setError,
                                                        ) => {
                                                          try {
                                                            setDisabled();
                                                            await ServiceApi.delete(
                                                              id,
                                                              {
                                                                signal:
                                                                  pageUnmountController.signal,
                                                              },
                                                            );
                                                            root.unmount();
                                                            if (
                                                              pageUnmountController
                                                                .signal.aborted
                                                            ) {
                                                              return;
                                                            }

                                                            dispatch({
                                                              id,
                                                              type: ActionType.RemoveService,
                                                            });
                                                          } catch (error) {
                                                            console.error(
                                                              error,
                                                            );
                                                            if (
                                                              pageUnmountController
                                                                .signal.aborted
                                                            ) {
                                                              return;
                                                            }

                                                            setError(
                                                              "Nepavyko ištrinti paslaugos įrašo. Prašome bandyti vėliau.",
                                                            );
                                                          }
                                                        }}
                                                      />,
                                                    );
                                                  }}
                                                >
                                                  Keisti
                                                </button>
                                              ) : (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  fill="currentColor"
                                                  className="bi bi-arrows-move"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"
                                                  />
                                                </svg>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                },
                              )}

                              {droppableProvided.placeholder}
                            </tbody>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
