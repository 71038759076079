import { v4 as uuid } from "uuid";

import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";
import { Category } from "./Organization";

const BASE_URL = `${API_BASE_URL}/api/Category`;

export const CategoryApi = {
    async create(name: string): Promise<Category> {
        const result = await fetch(BASE_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify({
                Id: uuid(),
                Name: name,
            }),
        });

        if (!result.ok) {
            throw await result.json();
        }

        return result.json();
    },
    async update(id: string, name: string): Promise<Category> {
        const result = await fetch(`${BASE_URL}/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify({
                Id: id,
                Name: name,
            }),
        });

        if (!result.ok) {
            throw await result.json();
        }

        return result.json();
    },
    async delete(id: string): Promise<void> {
        const result = await fetch(`${BASE_URL}/${id}`, {
            method: "DELETE",
            headers: {
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
};
