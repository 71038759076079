import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";
import { Employee } from "./Organization";

interface Options {
    signal?: AbortSignal;
}

export interface CreateEmployeeDto {
    id: string;
    organizationId: string;
    displayName: string;
    description: string | null;
}

export interface UpdateEmployeeDto {
    displayName: string;
    description: string | null;
    email: string | null;
}

const BASE_URL = `${API_BASE_URL}/api/Worker`;

export const WorkerApi = {
    async getAll(requestOptions: Options): Promise<Employee[]> {
        return (
            await fetch(BASE_URL, {
                method: "GET",
                headers: {
                    "X-OrganizationId": ORGANIZATION_ID,
                    Authorization: await getAuthorizationHeader(),
                },
                ...requestOptions,
            })
        ).json();
    },
    async create(createEmployeeDto: CreateEmployeeDto) {
        const result = await fetch(BASE_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify(createEmployeeDto),
        });

        if (!result.ok) {
            throw result.json();
        }

        return result.json();
    },
    async delete(id: string, requestOptions: Options) {
        const result = await fetch(`${BASE_URL}/${id}`, {
            method: "DELETE",
            headers: {
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async update(
        id: string,
        updateEmployeeDto: UpdateEmployeeDto,
        requestOptions: Options,
    ) {
        const result = await fetch(`${BASE_URL}/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify(updateEmployeeDto),
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }

        return result.json();
    },
    getPhotoUrl(workerId: string, version?: string) {
        return `${BASE_URL}/${ORGANIZATION_ID}/${workerId}/photo${
            version ? `?v=${version}` : ""
        }`;
    },
    async uploadPhoto(
        workerId: string,
        formData: FormData,
        requestOptions?: Options,
    ) {
        const result = await fetch(`${BASE_URL}/${workerId}/photo`, {
            method: "POST",
            headers: {
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: formData,
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
};
