export enum Status {
    Pending = "pending",
    Loaded = "loaded",
    Error = "error",
}

export type ResponseDto<TData> =
    | {
          value: TData;
          status: Status.Loaded;
      }
    | { value?: TData; status: Status.Pending }
    | { value: undefined; status: Status.Error };
