import { ServiceEmployee } from "../api/Organization";

export const getPriceTitle = (workerIdList: ServiceEmployee[]) => {
    if (!workerIdList.length) {
        return "-";
    }

    const extremes = workerIdList.reduce(
        (result, serviceEmployee) => {
            if (result.max < serviceEmployee.price) {
                result.max = serviceEmployee.price;
            }

            if (result.min > serviceEmployee.price) {
                result.min = serviceEmployee.price;
            }

            return result;
        },
        {
            min: workerIdList[0].price,
            max: workerIdList[0].price,
        }
    );

    if (extremes.min === extremes.max) {
        return `${extremes.min} EUR`;
    }

    return `${extremes.min} - ${extremes.max} EUR`;
};
