import format from "date-fns/format";
import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";

export interface Visit {
    id: string;
    title: string;
    start: Date;
    end: Date;
    workerId: string;
    serviceId: string;
    client?: {
        name: string;
        phone: string;
    };
    skipNotification: boolean;
}

export interface ApiVisit extends Omit<Visit, "start" | "end"> {
    start: string;
    end: string;
    date: string;
}

interface CreateVisitDto {
    id: string;
    organizationId: string;
    start: string;
    serviceWorkerMap: {
        serviceId: string;
        workerId: string;
    }[];
    skipClientSmsNotification: boolean;
    client: {
        name: string;
        phone: string;
    };
}

interface UpdateVisitDto {
    price: number;
    skipClientSmsNotification: boolean;
    client: {
        name: string;
        phone: string;
    };
}

interface UpdateVisitTimeDto {
    id: string;
    start: Date;
    end: Date;
}

interface VisitAvailabiliyRequest {
    serviceWorkerMap: ServiceAvailability[];
    organizationId: string;
}

export interface ServiceAvailability {
    serviceId: string;
    /**
     * Send no worker id when all employes selected.
     */
    workerId?: string;
}

export interface TimeRange {
    start: string;
    end: string;
}

interface DayAvailability {
    date: string;
    slots: TimeRange[];
}

export interface VisitAvailabilityResponse {
    daysAvailabilities: DayAvailability[];
}

interface Options {
    signal?: AbortSignal;
}

const BASE_URL = `${API_BASE_URL}/api/Visit`;

export const VisitApi = {
    async create(createVisitDto: CreateVisitDto, requestOptions: Options) {
        const result = await fetch(`${BASE_URL}/Booking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            body: JSON.stringify(createVisitDto),
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }

        return result.json();
    },
    async delete(id: string, requestOptions: Options) {
        const result = await fetch(`${BASE_URL}/${id}`, {
            method: "DELETE",
            headers: {
                "X-OrganizationId": ORGANIZATION_ID,
                Authorization: await getAuthorizationHeader(),
            },
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async updateDetails(
        id: string,
        updateVisitDto: UpdateVisitDto,
        requestOptions: Options,
    ): Promise<void> {
        const result = await fetch(`${BASE_URL}/${id}/detail`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthorizationHeader(),
                "X-OrganizationId": ORGANIZATION_ID,
            },
            body: JSON.stringify(updateVisitDto),
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async updateTime(
        id: string,
        updateTimeDto: UpdateVisitTimeDto,
        requestOptions: Options,
    ): Promise<void> {
        const result = await fetch(`${BASE_URL}/${id}/time`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthorizationHeader(),
                "X-OrganizationId": ORGANIZATION_ID,
            },
            body: JSON.stringify({
                ...updateTimeDto,
                date: format(updateTimeDto.start, "y-MM-dd"),
                start: format(updateTimeDto.start, "HH:mm"),
                end: format(updateTimeDto.end, "HH:mm"),
            }),
            ...requestOptions,
        });

        if (!result.ok) {
            throw await result.json();
        }
    },
    async search(
        workerIds: string[],
        startDate: string,
        endDate: string,
        requestOptions: Options,
    ): Promise<Visit[]> {
        return fetch(`${BASE_URL}/search`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: await getAuthorizationHeader(),
                "X-OrganizationId": ORGANIZATION_ID,
            },
            body: JSON.stringify({
                workerIds,
                startDate: format(new Date(startDate), "y-MM-dd"),
                endDate: format(new Date(endDate), "y-MM-dd"),
            }),
            ...requestOptions,
        }).then(async (result) => {
            const apiEvent = await result.json();
            return apiEvent.map((apiEvent: ApiVisit) => ({
                ...apiEvent,
                start: new Date(`${apiEvent.date}T${apiEvent.start}`),
                end: new Date(`${apiEvent.date}T${apiEvent.end}`),
            }));
        });
    },
    availability: async (
        options: VisitAvailabiliyRequest,
        requestOptions: Options,
    ): Promise<VisitAvailabilityResponse> => {
        const response = await fetch(`${BASE_URL}/Availability`, {
            method: "POST",
            body: JSON.stringify(options),
            headers: {
                "Content-Type": "application/json",
            },
            ...requestOptions,
        });

        if (response.status === 204) {
            return {
                daysAvailabilities: [],
            };
        }

        return response.json();
    },
};
