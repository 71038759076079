import { ReactNode, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ShowByUserType } from "../show-by-user-type/show-by-user-type";
import { WorkerApi } from "../../api/Worker";
import { UserContext } from "../../contexts/user-context";
import { AsyncImage } from "../async-image/async-image";
import s from "./MainLayout.module.scss";

interface Props {
    children: ReactNode;
    className?: string;
}

export const MainLayout = ({ children, className }: Props) => {
    const { userInfo, userPhotoVersion } = useContext(UserContext);

    const resolveLinkClassName = ({ isActive }: { isActive: boolean }) =>
        classNames("nav-link link-dark", { active: isActive });
    return (
        <div className={s.mainLayout}>
            <nav className="navbar navbar-dark bg-dark">
                <div className="container-fluid">
                    <AuthenticatedTemplate>
                        <button
                            className={classNames("navbar-toggler", s.burger)}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarsExample05"
                            aria-controls="navbarsExample05"
                            aria-expanded="true"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </AuthenticatedTemplate>
                    <Link className="navbar-brand" to="/">
                        Samis
                        <AuthenticatedTemplate>
                            <span
                                className={s.mobileOrganizationName}
                            >{` - Anima`}</span>
                        </AuthenticatedTemplate>
                    </Link>

                    <div className={s.mobileAside}>
                        <Link className={s.mobileName} to="/profile">
                            {userInfo?.userDisplayName}
                        </Link>
                    </div>
                    <AuthenticatedTemplate>
                        <div
                            className="navbar-collapse collapse"
                            id="navbarsExample05"
                        >
                            <ul
                                className={classNames(
                                    "navbar-nav me-auto my-3 mb-lg-0",
                                    s.navList,
                                )}
                            >
                                <li className="nav-item">
                                    <NavLink
                                        to="/schedule-week"
                                        className={resolveLinkClassName}
                                        aria-current="page"
                                    >
                                        Grafikas
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/working-time"
                                        className={resolveLinkClassName}
                                    >
                                        Darbo laikas
                                    </NavLink>
                                </li>
                                <ShowByUserType fallback={null}>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/workers"
                                            className={resolveLinkClassName}
                                        >
                                            Darbuotojai
                                        </NavLink>
                                    </li>
                                </ShowByUserType>
                                <ShowByUserType fallback={null}>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/services"
                                            className={resolveLinkClassName}
                                        >
                                            Paslaugos
                                        </NavLink>
                                    </li>
                                </ShowByUserType>
                                <ShowByUserType fallback={null}>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/service-categories"
                                            className={resolveLinkClassName}
                                        >
                                            Paslaugų kategorijos
                                        </NavLink>
                                    </li>
                                </ShowByUserType>
                                {/* TODO: Implement when/if necessary. */}
                                {/* <ShowByUserType fallback={null}>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/settings"
                                            className={resolveLinkClassName}
                                        >
                                            Nustatymai
                                        </NavLink>
                                    </li>
                                </ShowByUserType> */}
                                <li className="nav-item">
                                    <NavLink
                                        to="/profile"
                                        className={resolveLinkClassName}
                                    >
                                        Profilis
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/logout"
                                        className="nav-link link-dark"
                                    >
                                        Atsijungti
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </AuthenticatedTemplate>
                </div>
            </nav>
            <div className={s.contentBody}>
                <AuthenticatedTemplate>
                    <div
                        className={classNames(
                            s.sidebar,
                            "d-flex flex-column flex-shrink-0 p-3 bg-light",
                        )}
                    >
                        <Link
                            to="/"
                            className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
                        >
                            <span className="fs-4">Anima</span>
                        </Link>
                        <hr />
                        <ul className="nav nav-pills flex-column mb-auto">
                            <li className="nav-item">
                                <NavLink
                                    to="/schedule-week"
                                    className={resolveLinkClassName}
                                    aria-current="page"
                                >
                                    Grafikas
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/working-time"
                                    className={resolveLinkClassName}
                                >
                                    Darbo laikas
                                </NavLink>
                            </li>
                            <ShowByUserType fallback={null}>
                                <li>
                                    <NavLink
                                        to="/workers"
                                        className={resolveLinkClassName}
                                    >
                                        Darbuotojai
                                    </NavLink>
                                </li>
                            </ShowByUserType>
                            <ShowByUserType fallback={null}>
                                <li>
                                    <NavLink
                                        to="/services"
                                        className={resolveLinkClassName}
                                    >
                                        Paslaugos
                                    </NavLink>
                                </li>
                            </ShowByUserType>
                            <ShowByUserType fallback={null}>
                                <li>
                                    <NavLink
                                        to="/service-categories"
                                        className={resolveLinkClassName}
                                    >
                                        Paslaugų kategorijos
                                    </NavLink>
                                </li>
                            </ShowByUserType>
                        </ul>
                        <hr />
                        <div className="dropdown">
                            <a
                                className={classNames(
                                    "d-flex align-items-center link-dark text-decoration-none dropdown-toggle",
                                    s.dropdownTrigger,
                                )}
                                id="dropdownUser2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {userInfo?.workerId ? (
                                    <AsyncImage
                                        src={WorkerApi.getPhotoUrl(
                                            userInfo.workerId,
                                            userPhotoVersion,
                                        )}
                                        className={classNames(
                                            "rounded-circle",
                                            "me-2",
                                            s.avatar,
                                        )}
                                        fallback={(status, { className }) => {
                                            return (
                                                <svg
                                                    version="1.2"
                                                    baseProfile="tiny-ps"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 25 30"
                                                    width="32"
                                                    height="32"
                                                    className={className}
                                                >
                                                    <path d="M24.98 24.81C24.98 26.23 24.57 27.45 23.76 28.46C22.94 29.48 21.96 29.98 20.82 29.99L4.16 29.99C3.01 29.99 2.03 29.48 1.22 28.46C0.41 27.45 0 26.23 0 24.81C0 23.71 0.05 22.67 0.16 21.68C0.27 20.7 0.48 19.71 0.78 18.72C1.08 17.72 1.46 16.87 1.92 16.16C2.38 15.45 3 14.87 3.76 14.42C4.52 13.98 5.39 13.75 6.38 13.75C8.09 15.42 10.12 16.25 12.49 16.25C14.85 16.25 16.89 15.42 18.59 13.75C19.58 13.75 20.46 13.98 21.22 14.42C21.98 14.87 22.59 15.45 23.05 16.16C23.51 16.87 23.89 17.72 24.2 18.72C24.5 19.71 24.7 20.7 24.81 21.68C24.91 22.67 24.97 23.71 24.98 24.81ZM19.98 7.51C19.98 9.57 19.25 11.34 17.78 12.8C16.32 14.27 14.55 15 12.49 15C10.42 15 8.65 14.27 7.19 12.8C5.72 11.34 4.99 9.57 5 7.51C5 5.44 5.73 3.67 7.19 2.21C8.65 0.75 10.41 0.01 12.49 0.01C14.56 0.01 16.32 0.75 17.78 2.21C19.24 3.67 19.97 5.44 19.98 7.51L19.98 7.51Z"></path>
                                                </svg>
                                            );
                                        }}
                                    />
                                ) : (
                                    <svg
                                        version="1.2"
                                        baseProfile="tiny-ps"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 25 30"
                                        width="32"
                                        height="32"
                                        className={classNames(
                                            "rounded-circle",
                                            "me-2",
                                            s.avatar,
                                        )}
                                    >
                                        <path d="M24.98 24.81C24.98 26.23 24.57 27.45 23.76 28.46C22.94 29.48 21.96 29.98 20.82 29.99L4.16 29.99C3.01 29.99 2.03 29.48 1.22 28.46C0.41 27.45 0 26.23 0 24.81C0 23.71 0.05 22.67 0.16 21.68C0.27 20.7 0.48 19.71 0.78 18.72C1.08 17.72 1.46 16.87 1.92 16.16C2.38 15.45 3 14.87 3.76 14.42C4.52 13.98 5.39 13.75 6.38 13.75C8.09 15.42 10.12 16.25 12.49 16.25C14.85 16.25 16.89 15.42 18.59 13.75C19.58 13.75 20.46 13.98 21.22 14.42C21.98 14.87 22.59 15.45 23.05 16.16C23.51 16.87 23.89 17.72 24.2 18.72C24.5 19.71 24.7 20.7 24.81 21.68C24.91 22.67 24.97 23.71 24.98 24.81ZM19.98 7.51C19.98 9.57 19.25 11.34 17.78 12.8C16.32 14.27 14.55 15 12.49 15C10.42 15 8.65 14.27 7.19 12.8C5.72 11.34 4.99 9.57 5 7.51C5 5.44 5.73 3.67 7.19 2.21C8.65 0.75 10.41 0.01 12.49 0.01C14.56 0.01 16.32 0.75 17.78 2.21C19.24 3.67 19.97 5.44 19.98 7.51L19.98 7.51Z"></path>
                                    </svg>
                                )}

                                <strong>{userInfo?.userDisplayName}</strong>
                            </a>
                            <ul
                                className="dropdown-menu text-small shadow"
                                aria-labelledby="dropdownUser"
                            >
                                {/* TODO: Implement when/if necessary. */}
                                {/* <ShowByUserType fallback={null}>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to="/settings"
                                        >
                                            Nustatymai
                                        </Link>
                                    </li>
                                </ShowByUserType> */}
                                <li>
                                    <Link
                                        className="dropdown-item"
                                        to="/profile"
                                    >
                                        Profilis
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <Link
                                        className="dropdown-item"
                                        to="/logout"
                                    >
                                        Atsijungti
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </AuthenticatedTemplate>
                <div className={classNames(className, s.children)}>
                    {children}
                </div>
            </div>
        </div>
    );
};
