import { Reducer, useEffect, useReducer } from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import { Status } from "../api/ApiContracts";
import { Employee, Organization, UserType } from "../api/Organization";
import { WorkerApi } from "../api/Worker";
import { AsyncImage } from "../components/async-image/async-image";
import { EmployeeModal } from "../components/employee-modal/EmployeeModal";
import { EmployeeImageModal } from "../components/employee-image-modal/employee-image-modal";
import { ErrorSection } from "../components/error-section/ErrorSection";
import { Loader } from "../components/loader";
import { MainLayout } from "../components/main-layout/MainLayout";
import { useIsMounted } from "../hooks/useIsMounted";
import { ModalLayout } from "../components/modal/ModalLayout";
import s from "./EmployeesPage.module.scss";

interface State {
  employees: Employee[];
  employeesStatus: Status;
  employeesFetchId: number;
  organizationId?: string;
  employeePhotoVersions: Record<string, string>;
}

enum ActionType {
  AddEmployee = "add-event",
  UpdateEmployee = "update-event",
  RemoveEmployee = "remove-employee",
  SetEmployeesStatus = "set-status",
  RetryEmployeesLoad = "retry-employees-load",
  RefreshEmployeePhoto = "refresh-employee-photo",
}

interface AddEmployeeAction {
  type: ActionType.AddEmployee;
  employee: Employee;
}

interface UpdateEmployeeAction {
  type: ActionType.UpdateEmployee;
  employee: Employee;
}

interface SetStatusAction {
  type: ActionType.SetEmployeesStatus;
  status: Status;
  employees?: Employee[];
  organizationId?: string;
}

interface RetryEmployeesLoadAction {
  type: ActionType.RetryEmployeesLoad;
}

interface RemoveEmployee {
  type: ActionType.RemoveEmployee;
  id: string;
}

interface RefreshEmployeePhoto {
  type: ActionType.RefreshEmployeePhoto;
  id: string;
}

type Action =
  | AddEmployeeAction
  | UpdateEmployeeAction
  | SetStatusAction
  | RetryEmployeesLoadAction
  | RemoveEmployee
  | RefreshEmployeePhoto;

export const EmployeesPage = () => {
  const isMounted = useIsMounted();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    (state, action) => {
      switch (action.type) {
        case ActionType.AddEmployee: {
          return {
            ...state,
            employees: [...state.employees, action.employee],
          };
        }
        case ActionType.UpdateEmployee: {
          return {
            ...state,
            employees: state.employees.reduce<Employee[]>(
              (newEmployee, employee) => {
                if (employee.id === action.employee.id) {
                  newEmployee.push(action.employee);
                } else {
                  newEmployee.push(employee);
                }

                return newEmployee;
              },
              [],
            ),
          };
        }
        case ActionType.SetEmployeesStatus: {
          return {
            ...state,
            employees: action.employees || [],
            employeesStatus: action.status,
            organizationId: action.organizationId,
            employeePhotoVersions: (action.employees || []).reduce<
              Record<string, string>
            >((versions, employee) => {
              versions[employee.id] = uuid();
              return versions;
            }, {}),
          };
        }
        case ActionType.RetryEmployeesLoad: {
          return {
            ...state,
            employees: [],
            employeesStatus: Status.Pending,
            employeesFetchId: state.employeesFetchId + 1,
          };
        }
        case ActionType.RemoveEmployee: {
          return {
            ...state,
            employees: state.employees.filter(({ id }) => id !== action.id),
          };
        }
        case ActionType.RefreshEmployeePhoto: {
          return {
            ...state,
            employeePhotoVersions: {
              ...state.employeePhotoVersions,
              [action.id]: uuid(),
            },
          };
        }
      }
    },
    {
      employees: [],
      employeesStatus: Status.Pending,
      employeesFetchId: 0,
      organizationId: undefined,
      employeePhotoVersions: {},
    },
  );

  useEffect(() => {
    const abortController = new AbortController();
    const loadData = async () => {
      try {
        const organizationInfo = await Organization.InsideOrganizationInfo({
          signal: abortController.signal,
        });
        if (!isMounted()) {
          return;
        }

        dispatch({
          type: ActionType.SetEmployeesStatus,
          status: Status.Loaded,
          employees: organizationInfo.workerList,
          organizationId: organizationInfo.id,
        });
      } catch (error) {
        if (
          error instanceof DOMException &&
          (error.name === "AbortError" || error.code === 20 || !isMounted())
        ) {
          return;
        }

        console.error(error);
        dispatch({
          type: ActionType.SetEmployeesStatus,
          status: Status.Error,
        });
      }
    };
    loadData();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.employeesFetchId]);

  const pageUnmountController = new AbortController();
  useEffect(() => {
    return () => pageUnmountController.abort();
  }, []);

  if (state.employeesStatus === Status.Pending) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  const organizationId = state.organizationId;
  if (state.employeesStatus === Status.Error || organizationId == null) {
    return (
      <MainLayout>
        <ErrorSection
          onRetryClick={() =>
            dispatch({
              type: ActionType.RetryEmployeesLoad,
            })
          }
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="container">
        <div className="d-flex flex-row align-items-center">
          <h1 className="py-4">Darbuotojai</h1>
          <button
            type="button"
            className="btn btn-primary ms-4"
            onClick={() => {
              const root = createRoot(document.getElementById("modal")!);
              root.render(
                <EmployeeModal
                  onSave={async (newEmployee, setError, setDisabled) => {
                    try {
                      setDisabled();
                      const employee = {
                        ...newEmployee,
                        id: uuid(),
                        organizationId,
                      };
                      await WorkerApi.create(employee);

                      root.unmount();

                      if (pageUnmountController.signal.aborted) {
                        return;
                      }

                      dispatch({
                        type: ActionType.AddEmployee,
                        employee,
                      });
                    } catch (error) {
                      console.error(error);
                      if (pageUnmountController.signal.aborted) {
                        return;
                      }

                      setError(
                        "Sistemos klaida. Nepavyko sukurti darbuotojo. Bandykite dar kartą vėliau.",
                      );
                    }
                  }}
                  onClose={() => {
                    root.unmount();
                  }}
                />,
              );
            }}
          >
            Sukurti įrašą
          </button>
        </div>
        <div className={s.tableContainer}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Nuotrauka</th>
                <th scope="col">Pavadinimas</th>
                <th scope="col">Aprašymas</th>
                <th scope="col">Veiksmai</th>
              </tr>
            </thead>
            <tbody>
              {state.employees.map((employee) => {
                const { id, displayName, description, userType } = employee;
                return (
                  <tr key={id}>
                    <td>
                      <AsyncImage
                        src={WorkerApi.getPhotoUrl(
                          id,
                          state.employeePhotoVersions[id],
                        )}
                        className={classNames(
                          "rounded-circle",
                          "me-2",
                          s.avatar,
                        )}
                        fallback={(status, { className }) => {
                          return (
                            <svg
                              version="1.2"
                              baseProfile="tiny-ps"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 25 30"
                              width="42"
                              height="42"
                              className={className}
                            >
                              <path d="M24.98 24.81C24.98 26.23 24.57 27.45 23.76 28.46C22.94 29.48 21.96 29.98 20.82 29.99L4.16 29.99C3.01 29.99 2.03 29.48 1.22 28.46C0.41 27.45 0 26.23 0 24.81C0 23.71 0.05 22.67 0.16 21.68C0.27 20.7 0.48 19.71 0.78 18.72C1.08 17.72 1.46 16.87 1.92 16.16C2.38 15.45 3 14.87 3.76 14.42C4.52 13.98 5.39 13.75 6.38 13.75C8.09 15.42 10.12 16.25 12.49 16.25C14.85 16.25 16.89 15.42 18.59 13.75C19.58 13.75 20.46 13.98 21.22 14.42C21.98 14.87 22.59 15.45 23.05 16.16C23.51 16.87 23.89 17.72 24.2 18.72C24.5 19.71 24.7 20.7 24.81 21.68C24.91 22.67 24.97 23.71 24.98 24.81ZM19.98 7.51C19.98 9.57 19.25 11.34 17.78 12.8C16.32 14.27 14.55 15 12.49 15C10.42 15 8.65 14.27 7.19 12.8C5.72 11.34 4.99 9.57 5 7.51C5 5.44 5.73 3.67 7.19 2.21C8.65 0.75 10.41 0.01 12.49 0.01C14.56 0.01 16.32 0.75 17.78 2.21C19.24 3.67 19.97 5.44 19.98 7.51L19.98 7.51Z"></path>
                            </svg>
                          );
                        }}
                      />
                    </td>
                    <td>{displayName}</td>
                    <td>{description}</td>
                    <td>
                      <button
                        type="button"
                        className={classNames(
                          "btn btn-primary",
                          s.changeButton,
                        )}
                        onClick={() => {
                          const root = createRoot(
                            document.getElementById("modal")!,
                          );
                          root.render(
                            <EmployeeModal
                              initialData={employee}
                              onSave={async (
                                updateEmployee,
                                setError,
                                setDisabed,
                              ) => {
                                try {
                                  setDisabed();
                                  await WorkerApi.update(
                                    updateEmployee.id,
                                    {
                                      description: updateEmployee.description,
                                      displayName: updateEmployee.displayName,
                                      email: updateEmployee.email,
                                    },
                                    {
                                      signal: pageUnmountController.signal,
                                    },
                                  );
                                  root.unmount();

                                  if (pageUnmountController.signal.aborted) {
                                    return;
                                  }

                                  dispatch({
                                    type: ActionType.UpdateEmployee,
                                    employee: updateEmployee,
                                  });
                                } catch (error) {
                                  console.error(error);
                                  if (pageUnmountController.signal.aborted) {
                                    return;
                                  }
                                  setError(
                                    "Nepayko atnaujinti darbuotojo įrašo. Bandykite vėliau.",
                                  );
                                }
                              }}
                              onRemove={async (setDisabled, setError) => {
                                try {
                                  setDisabled();
                                  await WorkerApi.delete(id, {
                                    signal: pageUnmountController.signal,
                                  });
                                  root.unmount();
                                  if (pageUnmountController.signal.aborted) {
                                    return;
                                  }

                                  dispatch({
                                    id,
                                    type: ActionType.RemoveEmployee,
                                  });
                                } catch (error) {
                                  console.error(error);
                                  if (pageUnmountController.signal.aborted) {
                                    return;
                                  }

                                  setError(
                                    "Nepavyko ištrinti darbuotojo įrašo. Bandykite vėliau.",
                                  );
                                }
                              }}
                              onClose={() => {
                                root.unmount();
                              }}
                            />,
                          );
                        }}
                      >
                        Keisti
                      </button>
                      {userType >= UserType.ManagerWorker ? (
                        <button
                          type="button"
                          className={classNames(
                            "btn btn-primary",
                            s.addPhotoButton,
                          )}
                          onClick={() => {
                            const root = createRoot(
                              document.getElementById("modal")!,
                            );
                            root.render(
                              <EmployeeImageModal
                                workerId={id}
                                onSave={async (
                                  formData,
                                  setError,
                                  setDisabed,
                                ) => {
                                  try {
                                    setDisabed();
                                    await WorkerApi.uploadPhoto(id, formData);
                                    dispatch({
                                      id,
                                      type: ActionType.RefreshEmployeePhoto,
                                    });
                                    root.unmount();
                                    const newRoot = createRoot(
                                      document.getElementById("modal")!,
                                    );
                                    newRoot.render(
                                      <ModalLayout
                                        autoFocusCancel
                                        title="Nuotrauka sėkmingai atnaujinta"
                                        cancelTitle="Supratau"
                                        onClose={() => newRoot.unmount()}
                                      >
                                        <p>
                                          Jūsų nuotrauka buvo sėkmingai
                                          atnaujinta.
                                        </p>
                                        <p>
                                          <strong>Dėmesio!</strong>
                                        </p>
                                        <p>
                                          Nuotraukos atnaujinimas sistemoje gali
                                          užtrukti iki pusvalandžio.
                                          <br />
                                          <strong>
                                            Tuo metu sistema gali rodyti seną
                                            nuotrauką.
                                          </strong>
                                        </p>
                                      </ModalLayout>,
                                    );

                                    if (pageUnmountController.signal.aborted) {
                                      return;
                                    }
                                  } catch (error) {
                                    console.error(error);
                                    if (pageUnmountController.signal.aborted) {
                                      return;
                                    }
                                    setError("Nepavyko įkelti nuotraukos.");
                                  }
                                }}
                                onClose={() => {
                                  root.unmount();
                                }}
                              />,
                            );
                          }}
                        >
                          Įkelti nuotruką
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </MainLayout>
  );
};
