import { createContext } from "react";
import { UserInfo } from "../api/Organization";

export interface UserContext {
    userInfo: UserInfo | null;
    userPhotoVersion: string;
    refreshPhoto: () => void;
}

// @ts-expect-error we'll make it non-undefined in the use case.
export const UserContext = createContext<UserContext>(undefined);
