import { getAuthorizationHeader } from "../helpers/auth-helpers";
import { API_BASE_URL, ORGANIZATION_ID } from "./api-config";
const BASE_URL = `${API_BASE_URL}/api/client`;

export interface Client {
  id: string;
  name: string;
  normalizedPhone: string;
  organizationId: string;
}

export const Client = {
  searchPhone: async (phoneNumber: string): Promise<Client[]> => {
    const response = await fetch(`${BASE_URL}/searchPhone/${phoneNumber}`, {
      method: "GET",
      headers: {
        "X-OrganizationId": ORGANIZATION_ID,
        Authorization: await getAuthorizationHeader(),
      },
    });

    if (response.status !== 200) {
      console.error(response.statusText);
    }

    return response.status === 200 ? response.json() : [];
  },
  searchName: async (name: string): Promise<Client[]> => {
    const response = await fetch(`${BASE_URL}/searchName/${name}`, {
      method: "GET",
      headers: {
        "X-OrganizationId": ORGANIZATION_ID,
        Authorization: await getAuthorizationHeader(),
      },
    });

    if (response.status !== 200) {
      console.error(response.statusText);
    }

    return response.status === 200 ? response.json() : [];
  },
};
