import ReactDatePicker, {
    ReactDatePickerProps,
    registerLocale,
    setDefaultLocale,
} from "react-datepicker";
import lt from "date-fns/locale/lt";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.scss";

registerLocale("lt", lt);
setDefaultLocale("lt");

interface Props
    extends Omit<
        ReactDatePickerProps,
        "inline" | "showTimeSelect" | "timeCaption" | "timeIntervals"
    > {}

export const DatePicker = (props: Props) => {
    return (
        <ReactDatePicker
            inline
            showTimeSelect
            timeCaption="laikas"
            timeIntervals={15}
            {...props}
        />
    );
};
